export enum DescontosPossiveisEnum {
  Invalido = 0,
  ComprasFarmaciasConveniada = 1,
  SeguroRendaIncapacidadeTemporaria = 2,
  ValeTransporte = 3,
  AssistenciaMedicaPlanoSaude = 4,
  AssistenciaMedicaPlanoSaudePais = 5,
  PlanoOdontologico = 6,
  SeguroVida = 7,
  ValeAlimentacao = 8,
  Refeicao = 9,
  ValeCombustivel = 10,
  EmprestimoPessoal = 11,
  GremioDespesas = 12,
  ContribuicaoSindical = 13,
  NaoAutorizoNenhumDescontoAcima = 14,
}

export const DescontosPossiveisValues = [
  {
    value: DescontosPossiveisEnum.ComprasFarmaciasConveniada,
    name: 'Compras efetuadas em farmácias conveniada',
  },
  {
    value: DescontosPossiveisEnum.SeguroRendaIncapacidadeTemporaria,
    name: 'Seguro de renda por incapacidade temporária - SERIT',
  },
  { value: DescontosPossiveisEnum.ValeTransporte, name: 'Vale Transporte' },
  {
    value: DescontosPossiveisEnum.AssistenciaMedicaPlanoSaude,
    name: 'Assistência Médica Plano de Saúde',
  },
  {
    value: DescontosPossiveisEnum.AssistenciaMedicaPlanoSaudePais,
    name: 'Assistência Médica Plano de Saúde Pais',
  },
  { value: DescontosPossiveisEnum.PlanoOdontologico, name: 'Plano Odontológico' },
  { value: DescontosPossiveisEnum.SeguroVida, name: 'Seguro de Vida' },
  { value: DescontosPossiveisEnum.ValeAlimentacao, name: 'Vale Alimentação' },
  { value: DescontosPossiveisEnum.Refeicao, name: 'Refeição' },
  { value: DescontosPossiveisEnum.ValeCombustivel, name: 'Vale Combustível' },
  { value: DescontosPossiveisEnum.EmprestimoPessoal, name: 'Empréstimo Pessoal' },
  { value: DescontosPossiveisEnum.GremioDespesas, name: 'Grêmio/Despesas com Lazer' },
  { value: DescontosPossiveisEnum.ContribuicaoSindical, name: 'Contribuição Sindical' },
  {
    value: DescontosPossiveisEnum.NaoAutorizoNenhumDescontoAcima,
    name: 'Não autorizo nenhum dos descontos acima',
  },
]
