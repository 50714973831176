import { useEffect, useState } from 'react'

import { Divider, Grid } from '@material-ui/core'

import { Button, ButtonBox, TextField } from '~/components'

import { RelRelCompensacaoHorasTrabalhadasModelo2 } from '~/hooks/queries/CompensacaoHorasTrabalhadas/dtos/RelCompensacaoHorasTrabalhadasJornadaMainDTO'

interface StepModelo2Props {
  data: RelRelCompensacaoHorasTrabalhadasModelo2
  onCancel: () => void
  onChange: (d: RelRelCompensacaoHorasTrabalhadasModelo2) => void
}

export default function StepModelo2({ data: _d, onCancel, onChange }: StepModelo2Props) {
  const [data, setData] = useState(_d || {})

  useEffect(() => {
    setData(_d)
  }, [_d])

  function handleSubmit() {
    onChange(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Domingo"
          value={data?.domingo || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const domingo = e.target.value
            setData((prev) => ({ ...prev, domingo }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Segunda-Feira"
          value={data?.segunda || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const segunda = e.target.value
            setData((prev) => ({ ...prev, segunda }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Terça-Feira"
          value={data?.terca || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const terca = e.target.value
            setData((prev) => ({ ...prev, terca }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Quarta-Feira"
          value={data?.quarta || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const quarta = e.target.value
            setData((prev) => ({ ...prev, quarta }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Quinta-Feira"
          value={data?.quinta || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const quinta = e.target.value
            setData((prev) => ({ ...prev, quinta }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Sexta-Feira"
          value={data?.sexta || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const sexta = e.target.value
            setData((prev) => ({ ...prev, sexta }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Sábado"
          value={data?.sabado || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const sabado = e.target.value
            setData((prev) => ({ ...prev, sabado }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Intervalo Alimentação"
          value={data?.intervaloAlimentacao || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const intervaloAlimentacao = e.target.value
            setData((prev) => ({ ...prev, intervaloAlimentacao }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Intervalo Lanche"
          value={data?.intervaloLanche || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const intervaloLanche = e.target.value
            setData((prev) => ({ ...prev, intervaloLanche }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Motivo da Compensação"
          value={data?.motivoCompensacao || ''}
          inputProps={{
            maxLength: 255,
          }}
          rows={4}
          multiline
          onChange={(e) => {
            const motivoCompensacao = e.target.value
            setData((prev) => ({ ...prev, motivoCompensacao }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Local e Data"
          value={data?.localData || ''}
          inputProps={{
            maxLength: 55,
          }}
          onChange={(e) => {
            const localData = e.target.value
            setData((prev) => ({ ...prev, localData }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Próximo
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
