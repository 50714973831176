import React from 'react'

import { Button as MuiButton, CircularProgress } from '@material-ui/core'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

export interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = (props) => {
  const { isLoading, children, disabled, ...rest } = props
  return (
    <MuiButton
      disabled={isLoading ? true : disabled}
      size="small"
      color="primary"
      variant="outlined"
      {...rest}
    >
      {isLoading ? <CircularProgress color="secondary" size={22} /> : children}
    </MuiButton>
  )
}

Button.defaultProps = {
  isLoading: false,
}

export default Button
