import { formatToCPF } from 'brazilian-values'

import { ToolsDataTable } from '~/components'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  query: string
  onRowSelected: (indexs: number[]) => void
  rowsSelected: number[]
  isLoading: boolean
  isFetching: boolean
  data: VinculoConsulta[]
}

export default function Table(props: TableProps) {
  const { query, data, isLoading, isFetching, onRowSelected, rowsSelected } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'pessoaCPF',
      label: 'CPF',
      options: {
        customBodyRender: formatToCPF,
      },
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
    },
    {
      name: 'dtAdmissao',
      label: 'Data de Admissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'dtRescisao',
      label: 'Data da Rescisão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      sherlock={{ columns: ['pessoaCPF', 'pessoaNome', 'dtAdmissao'], query }}
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        selectOnClick: true,
        type: 'multiple',
      }}
      disableAutoHeight
    />
  )
}
