import React, { useState } from 'react'

import api from '~/services/api-pessoal'

import { Grid, LinearProgress } from '@material-ui/core'

import InputMakeFormula from './InputMakeFormula'
import DialogMakeFormula from './DialogMakeFormula'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'
import { ButtonBox } from 'mio-library-ui'
import { Button, ContentDivider } from '~/components'
import { useEventoGetById } from '~/hooks/queries/Evento/useEventoGetById'

interface EventoFormulasProps {
  eventoId: string
  onClose: () => void
}

export default function EventoFormulas(props: EventoFormulasProps) {
  const { eventoId, onClose } = props

  const [isSubmiting, setSubmiting] = useState(false)
  const { data, isLoading, getData } = useEventoGetById(eventoId)

  const {
    isOpen: isOpenDialogFormula,
    close: closeDialogFormula,
    data: dataDialogFormula,
    open: openDialogFormula,
  } = useDialog<{ data: string; keyField: string }>({ data: '', keyField: '' })
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  async function handleSubmit(value: string, keyField: string) {
    setSubmiting(true)
    try {
      await api.put(`/Evento/${eventoId}`, { ...data, [keyField]: value })
      notification.put()
      getData()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider top={2} />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        {isLoading && <LinearProgress />}
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <InputMakeFormula
          label="Fórmula Mensalista"
          value={data?.formulaMensalista || ''}
          openFormula={(data) => openDialogFormula({ data, keyField: 'formulaMensalista' })}
        />
      </Grid>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <InputMakeFormula
          label="Fórmula Diarista"
          value={data?.formulaDiarista || ''}
          openFormula={(data) => openDialogFormula({ data, keyField: 'formulaDiarista' })}
        />
      </Grid>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <InputMakeFormula
          label="Fórmula Horista"
          value={data?.formulaHorista || ''}
          openFormula={(data) => openDialogFormula({ data, keyField: 'formulaHorista' })}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ButtonBox>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
        </ButtonBox>
      </Grid>

      <DialogMakeFormula
        isOpen={isOpenDialogFormula}
        onClose={closeDialogFormula}
        onAfterCreateFormula={handleSubmit}
        data={dataDialogFormula.data}
        keyField={dataDialogFormula.keyField}
        isSubmiting={isSubmiting}
      />
    </Grid>
  )
}
