import React, { useState, useCallback, useEffect } from 'react'

import { Box } from '@material-ui/core'
import { PageHeader, Button } from 'mio-library-ui'

import Table from './components/Table'
import Form from './components/Form'

import { ConfirmDeleteDialog, TreeView } from '~/components'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

const HEADER_HEIGHT = '70px'

const TrabalhoIntermitente = () => {
  const [vinculo, setVinculo] = useState({})

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu(
    [VinculoTipoEnum.Funcionario_1],
    CategoriaEnum.Empregado_Intermitente_111,
  )

  const getCollectionByVinculo = async (vinculoId) => {
    if (!vinculoId) return
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const response = await api.get(`VinculoIntermitente/GetByVinculo?vinculoId=${vinculoId}`)
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  useEffect(() => {
    setVinculo({})
    setCollection((oldState) => ({
      ...oldState,
      itens: [],
    }))
  }, [anoMes, estabelecimento])

  const handleClickItem = useCallback(
    (event, value) => {
      const handleClickEditItem = async (id) => {
        try {
          const response = await api.get(`VinculoIntermitente/${id}`)
          handleOpenForm(response.data.data)
        } catch (error) {
          console.log(error)
        }
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    const itens = collection.itens
    try {
      await api.delete(`/VinculoIntermitente/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }

    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
    //eslint-disable-next-line
  }, [vinculo])

  const handleOpenForm = (
    data = {
      vinculoId: vinculo?.id,
      vinculoIntermitenteItens: [],
    },
  ) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = async (data) => {
        const { itens } = collection
        const newItens = [data, ...itens]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))

        try {
          const response = await api.get(`VinculoIntermitente/${data?.id}`)
          handleOpenForm(response.data.data)
        } catch (error) {
          console.log(error)
        }
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    // eslint-disable-next-line
    [collection],
  )

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        getCollectionByVinculo(vinculo.vinculoId)
        setVinculo((oldState) => ({
          ...oldState,
          id: vinculo?.vinculoId,
          nome: vinculo?.vinculoNome,
        }))
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Trabalho Intermitente" subtitle={vinculo?.nome || '-'}>
          {vinculo?.id && (
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
        />
      </Box>

      <Form
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />
    </TreeView>
  )
}

export default TrabalhoIntermitente
