import { Container, PageHeader } from '~/components'
import StepperForm from '~/components/StepperForm'

import FeriasColetivaContent from './Content/FeriasColetiva'
import ImprimirFeriasColetiva from './Content/ImprimirFeriasColetiva'

export default function FeriasColetiva() {
  return (
    <StepperForm.Provider
      onSubmit={() => undefined}
      onCancel={() => undefined}
      disabledAlert
      data={{}}
      steps={[
        {
          label: 'Férias Coletiva',
          children: <FeriasColetivaContent />,
          icon: 'people',
        },
        {
          label: 'Imprimir Recibos',
          children: <ImprimirFeriasColetiva />,
          icon: 'print',
        },
      ]}
    >
      <Container>
        <PageHeader title="Férias Coletiva">
          <StepperForm.Steps orientation="horizontal" />
        </PageHeader>
        <StepperForm.Content />
      </Container>
    </StepperForm.Provider>
  )
}
