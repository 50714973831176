import React, { useEffect, useState } from 'react'

import { Divider, Grid, Typography } from '@material-ui/core'

import { ActionDialog, Checkbox } from '~/components'

import useAmbiente from '~/hooks/useAmbiente'
import useUtils from '~/hooks/useUtils'

const initialState = {
  isMarcarDescansoSemanal: true,
  isMarcarFeriado: true,
  isMarcarSabado: false,
  isMarcarFerias: true,
  isMarcarAfastamento: true,
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  const { formatAnoMes } = useUtils()
  const { anoMes } = useAmbiente()

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para a Folha de Ponto"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Competência: {formatAnoMes(anoMes)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label="Selecionar Descanso Semanal"
            value={data.isMarcarDescansoSemanal}
            onChange={(e, value) => {
              const isMarcarDescansoSemanal = value
              setData((prevState) => ({ ...prevState, isMarcarDescansoSemanal }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label="Selecionar Feriados"
            value={data.isMarcarFeriado}
            onChange={(e, value) => {
              const isMarcarFeriado = value
              setData((prevState) => ({ ...prevState, isMarcarFeriado }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label="Selecionar Sábados"
            value={data.isMarcarSabado}
            onChange={(e, value) => {
              const isMarcarSabado = value
              setData((prevState) => ({ ...prevState, isMarcarSabado }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label="Selecionar Férias"
            value={data.isMarcarFerias}
            onChange={(e, value) => {
              const isMarcarFerias = value
              setData((prevState) => ({ ...prevState, isMarcarFerias }))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            label="Selecionar Afastamentos"
            value={data.isMarcarAfastamento}
            onChange={(e, value) => {
              const isMarcarAfastamento = value
              setData((prevState) => ({ ...prevState, isMarcarAfastamento }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
