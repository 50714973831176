import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

interface RequestProps {
  empregadoresIds: string[]
  anoMes: string
}

export default function useProcessarCompetencia() {
  const dialogNotificaiton = useDialogNotification()
  const notification = useNotification()

  async function handleRequest({ empregadoresIds, anoMes }: RequestProps) {
    await api.post('/Competencia/ProcessarCompetencia', empregadoresIds, { params: { anoMes } })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notification.success('Processamento Iniciado com Sucesso'),
    onError: dialogNotificaiton.extractErrors,
  })
}
