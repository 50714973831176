import React, { useState, useEffect, useCallback } from 'react'

import { Grid, MenuItem } from '@material-ui/core'
import { TextField, ContentDivider } from 'mio-library-ui'

import { AutoCompleteNaturezaJuridica, MUIAutoComplete } from '~/components/AutoComplete'
import { DatePicker, ActionDialog, Checkbox } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import api from '~/services/api-pessoal'
import moment from 'moment'

import * as yup from 'yup'

import { IndCooperativaValues } from '~/@types/enums/IndCooperativaEnum'
import { IndConstrutoraValues } from '~/@types/enums/IndConstrutoraEnum'
import { indProdutorRuralOpcaoPrevidenciaValues } from '~/values/indProdutorRuralOpcaoPrevidenciaValues'
import { indDesoneracaoValues } from '~/values/indDesoneracaoValues'
import { ClassTribValues } from '~/@types/enums/ClassTribEnum'
import { getDateMinAnoMes } from '~/utils/utils'

const schema = yup.object().shape({
  dtSituacao: yup
    .string()
    .required('Informe a Data da Situação Tributária.')
    .when(['$anoMes', '$isUpdate'], (anoMes, isUpdate, schema, { value: dtSituacao }) => {
      if (isUpdate) return schema

      const dtSituacaoFormatted = moment(dtSituacao).format('YYYYMM')

      if (!moment(dtSituacaoFormatted).isSame(anoMes)) {
        return schema.min(
          dtSituacao,
          'A Data da Situação Tributária deve estar dentro da Competência Atual',
        )
      }

      return schema
    })
    .nullable(),
  classificacaoTributaria: yup.string().required('Informe a Classificação Tributária'),
  indCooperativa: yup.string().required('Informe o Ind. Cooperativa'),
  indConstrutora: yup.string().required('Informe o Ind. Construtora'),
  indProdutorRuralOpcaoPrevidencia: yup.string().required('Informe o Ind. Produção Rural'),
  indDesoneracao: yup.string().required('Informe o Ind. Desoneração'),
})

const Form = (props) => {
  const { anoMes, isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    if (_data?.id) {
      setData(_data)
    } else {
      setData({ ..._data, dtSituacao: getDateMinAnoMes(anoMes) })
    }
  }, [_data, anoMes])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const dtMovimentoESocial = moment(anoMes).format('yyyy-MM-DD')
        const response = await api.put(`/empregadorSituacao/${data.id}`, {
          ...data,
          dtMovimentoESocial,
        })
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/empregadorSituacao', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm, anoMes])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes, isUpdate: data?.id ? true : false },
    },
  })

  return (
    <ActionDialog
      title="Cadastro de Empregador | Situação Tributária"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Informações primárias" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <DatePicker
            label="Data da Situação Tributária"
            size="small"
            value={data.dtSituacao || null}
            disabled
            // disabled={data?.id ? true : false}
            // required={!data?.id ? true : false}
            validationErrors={validationErrors}
            name="dtSituacao"
            onChange={(date) => {
              const dtSituacao = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtSituacao })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
          <MUIAutoComplete
            label="Classificação Tributária"
            required
            validationErrors={validationErrors}
            name="classificacaoTributaria"
            value={data.classificacaoTributaria}
            options={ClassTribValues}
            optionId="value"
            renderOption={(option) => option.name}
            onChange={(e, obj) => {
              const classificacaoTributaria = obj ? obj.value : ''
              setData({
                ...data,
                classificacaoTributaria,
              })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Ind. Construtora"
            required
            validationErrors={validationErrors}
            name="indConstrutora"
            options={IndConstrutoraValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indConstrutora}
            onChange={(e, obj) => {
              const indConstrutora = obj ? obj.value : ''
              setData({ ...data, indConstrutora })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Ind. Cooperativa"
            options={IndCooperativaValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            validationErrors={validationErrors}
            name="indCooperativa"
            value={data.indCooperativa}
            onChange={(e, obj) => {
              const indCooperativa = obj ? obj.value : ''
              setData({ ...data, indCooperativa })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Ind. Produção Rural"
            required
            validationErrors={validationErrors}
            name="indProdutorRuralOpcaoPrevidencia"
            options={indProdutorRuralOpcaoPrevidenciaValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indProdutorRuralOpcaoPrevidencia}
            onChange={(e, obj) => {
              const indProdutorRuralOpcaoPrevidencia = obj ? obj.value : ''
              setData({ ...data, indProdutorRuralOpcaoPrevidencia })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Ind. Desoneração"
            required
            validationErrors={validationErrors}
            name="indDesoneracao"
            options={indDesoneracaoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indDesoneracao}
            onChange={(e, obj) => {
              const indDesoneracao = obj ? obj.value : ''
              setData({ ...data, indDesoneracao })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <AutoCompleteNaturezaJuridica
            value={data?.naturezaJuridicaId || ''}
            optionId="id"
            onChange={(e, obj) => {
              const naturezaJuridicaId = obj?.id || ''
              setData((oldState) => ({
                ...oldState,
                naturezaJuridicaId,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Opção Registro Eletrônico"
            fullWidth
            value={data.indOptRegEletronico || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const indOptRegEletronico = e.target.value
              setData({ ...data, indOptRegEletronico })
            }}
            select
          >
            <MenuItem value="0">Não optou pelo registro eletrônico de empregados</MenuItem>
            <MenuItem value="1">Optou pelo registro eletrônico de empregados</MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Checkbox
            title="Preenchimento exclusivo para o empregador em situação de tributação de PIS e COFINS sobre a folha de pagamento"
            label="Empresa com tributação PIS sobre Folha"
            value={data.isTribFolhaPisCofins}
            onChange={(e, checked) =>
              setData((prev) => ({ ...prev, isTribFolhaPisCofins: checked }))
            }
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Isenção" />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Aliq. Filantropia"
            fullWidth
            value={data?.aliqFilantropia || ''}
            variant="outlined"
            size="small"
            onlyNumber
            inputProps={{
              maxLength: 4,
            }}
            onChange={(e) => {
              const aliqFilantropia = e.target.value
              setData({ ...data, aliqFilantropia })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Ministério ou Lei"
            fullWidth
            value={data?.isencaoIdeMinLei || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 70,
            }}
            onChange={(e) => {
              const isencaoIdeMinLei = e.target.value
              setData({ ...data, isencaoIdeMinLei })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Nr. Certificado"
            fullWidth
            value={data?.isencaoNrCertif || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 40,
            }}
            onChange={(e) => {
              const isencaoNrCertif = e.target.value
              setData({ ...data, isencaoNrCertif })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <DatePicker
            label="Emissão Certificado"
            size="small"
            value={data.isencaoDtEmisCertif || null}
            onChange={(date) => {
              const isencaoDtEmisCertif = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, isencaoDtEmisCertif })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <DatePicker
            label="Vencimento Certificado"
            size="small"
            value={data.isencaoDtVencCertif || null}
            onChange={(date) => {
              const isencaoDtVencCertif = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, isencaoDtVencCertif })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TextField
            label="Nr. Protocolo Renovação"
            fullWidth
            value={data?.isencaoNrProtRenov || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 40,
            }}
            onChange={(e) => {
              const isencaoNrProtRenov = e.target.value
              setData({ ...data, isencaoNrProtRenov })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <DatePicker
            label="Protocolo Renovação"
            size="small"
            value={data.isencaoDtProtRenov || null}
            onChange={(date) => {
              const isencaoDtProtRenov = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, isencaoDtProtRenov })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <DatePicker
            label="Publicação DOU"
            size="small"
            value={data.isencaoDtDou || null}
            onChange={(date) => {
              const isencaoDtDou = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, isencaoDtDou })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
          <TextField
            label="Nr. Página no DOU"
            fullWidth
            value={data?.isencaoPagDou || ''}
            variant="outlined"
            size="small"
            onlyNumber
            onChange={(e) => {
              const isencaoPagDou = parseInt(e.target.value)
              setData({ ...data, isencaoPagDou })
            }}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
