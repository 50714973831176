import { createContext, useContext } from 'react'

import { IndDiasFeriasProporcionaisRestantesEnum } from '~/@types/enums/IndDiasFeriasProporcionaisRestantesEnum'
import {
  FeriasColetivaAlertaDTO,
  FeriasColetivaConcedidaProgramadaDTO,
  FeriasColetivaDTO,
  FeriasColetivaRequestDTO,
  FeriasColetivaRequestDTOForm,
} from '~/hooks/queries/FeriasColetiva/obterFuncionariosAnalitico'

export interface ParamsPageProps {
  isAbono: boolean
  isFilterBySetor: boolean
}

export interface ValuesMinProps {
  dtPagamentoFerias: string | null
  dtAvisoFerias: string | null
}

interface FeriasColetivaContextProps {
  formProps: {
    onChangeAbono: (checked: boolean) => void
    onChangeFilterSetor: (checked: boolean) => void
    paramsPage: ParamsPageProps
    isOpenCollapseForm: boolean
    setOpenCollapseForm: React.Dispatch<React.SetStateAction<boolean>>
    onCancel: () => void
    dataForm: FeriasColetivaRequestDTOForm
    setDataForm: React.Dispatch<React.SetStateAction<FeriasColetivaRequestDTOForm>>
    valuesMin: ValuesMinProps
    setValuesMin: React.Dispatch<React.SetStateAction<ValuesMinProps>>
  }
  tableProps: {
    query: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
    collection: FeriasColetivaDTO[]
    rowsSelected: number[]
    setRowsSelected: React.Dispatch<React.SetStateAction<number[]>>
  }
  dialogsProps: {
    openDialogAlerts: (data: FeriasColetivaAlertaDTO[]) => void
    isOpenDialogAlerts: boolean
    dataDialogAlerts: FeriasColetivaAlertaDTO[]
    closeDialogAlerts: () => void

    openDialogConcedidaProgramada: (props: {
      title: string
      data: FeriasColetivaConcedidaProgramadaDTO[]
    }) => void
    isOpenDialogConcedidaProgramada: boolean
    dataDialogConcedidaProgramada: { title: string; data: FeriasColetivaConcedidaProgramadaDTO[] }
    closeDialogConcedidaProgramada: () => void

    openDialogLicencaRemunerada: (dt: FeriasColetivaDTO) => void
    isOpenDialogLicencaRemunerada: boolean
    dataDialogLicencaRemunerada: FeriasColetivaDTO | null
    closeDialogLicencaRemunerada: () => void
  }
  requestProps: {
    onSearch: (data: FeriasColetivaRequestDTO) => void
    onProcess: () => void
    isLoadingSearch: boolean
    isSubmitting: boolean
  }
}

export const FeriasColetivaContext = createContext<FeriasColetivaContextProps>({
  formProps: {
    onChangeAbono: () => undefined,
    onChangeFilterSetor: () => undefined,
    paramsPage: {
      isAbono: false,
      isFilterBySetor: false,
    },
    isOpenCollapseForm: true,
    setOpenCollapseForm: () => undefined,
    onCancel: () => undefined,
    dataForm: {
      dtAvisoFerias: '',
      dtFimAbono: '',
      dtFimFerias: '',
      dtInicioAbono: '',
      dtInicioFerias: '',
      dtPagamentoFerias: '',
      estabelecimentoId: '',
      indDiasFeriasProporcionaisRestantes:
        IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Ferias_Atual_1,
      nrDiasAbono: 0,
      nrDiasFerias: 0,
      setorId: '',
    },
    setDataForm: () => undefined,
    valuesMin: {
      dtAvisoFerias: null,
      dtPagamentoFerias: null,
    },
    setValuesMin: () => undefined,
  },
  tableProps: {
    query: '',
    setQuery: () => undefined,
    collection: [],
    rowsSelected: [],
    setRowsSelected: () => undefined,
  },
  dialogsProps: {
    openDialogAlerts: () => undefined,
    isOpenDialogAlerts: false,
    dataDialogAlerts: [],
    closeDialogAlerts: () => undefined,

    openDialogConcedidaProgramada: () => undefined,
    isOpenDialogConcedidaProgramada: false,
    dataDialogConcedidaProgramada: { title: 'Férias', data: [] },
    closeDialogConcedidaProgramada: () => undefined,

    openDialogLicencaRemunerada: () => undefined,
    isOpenDialogLicencaRemunerada: false,
    dataDialogLicencaRemunerada: null,
    closeDialogLicencaRemunerada: () => undefined,
  },
  requestProps: {
    onProcess: () => undefined,
    onSearch: () => undefined,
    isLoadingSearch: false,
    isSubmitting: false,
  },
})

export const FeriasColetivaProvide = FeriasColetivaContext.Provider

export function useFeriasColetiva() {
  return useContext(FeriasColetivaContext)
}
