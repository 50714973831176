import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { CompetenciaEmpregadorDTO } from './CompetenciaEmpregadorDTO'
import { FiltroCompetenciaAberturaEnum } from '~/@types/enums/FiltroCompetenciaAberturaEnum'

const queryUseObterEmpregadores = '/Competencia/ObterEmpregadores'

interface RequestProps {
  anoMes: string
  filtroEmpresas: FiltroCompetenciaAberturaEnum
  grupoId: string | null
}

export default function useObterEmpregadores(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: CompetenciaEmpregadorDTO[] }>(
      queryUseObterEmpregadores,
      { params },
    )
    return response.data.data
  }
  return useQuery([queryUseObterEmpregadores, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
