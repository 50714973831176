import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Checkbox, FormControlLabel, Grid, LinearProgress } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { AlertContainer, Button, DatePickerNew, TextField } from '~/components'
import { AutoCompletePessoaFisica, MUIAutoComplete } from '~/components/AutoComplete'

import { dateHasBetweenYearMonth, verifyIfExistsMatricula } from '~/hooks/useUtils'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'
import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import useAmbiente from '~/hooks/useAmbiente'
import { useVinculoObterDataInicial } from '~/hooks/queries/Vinculo/useVinculoObterDataInicial'

import { CategoriaValues, CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { IndCadastroMatriculaEnum } from '~/@types/enums/IndCadastroMatriculaEnum'
import { PessoaFisica } from '~/hooks/queries/PessoaFisica/PessoaFisica'

const categoriasFuncionario = [
  CategoriaEnum.Trabalhador_Avulso_Portuario_201,
  CategoriaEnum.Trabalhador_Avulso_Nao_Portuario_202,
  CategoriaEnum.Dirigente_Sindical_401,
  CategoriaEnum.Trabalhador_Cedido_410,
  CategoriaEnum.Dirigente_Sindical_501,
]

const categoriasFuncionarioFiltred = CategoriaValues.filter(
  (categoria) =>
    (categoria.value >= CategoriaEnum.Empregado_Geral_101 &&
      categoria.value <= CategoriaEnum.Empregado_Intermitente_111) ||
    categoriasFuncionario.includes(categoria.value),
)

const schema = yup.object().shape({
  matricula: yup
    .string()
    .when(['isCadastroEnviadoeSocial'], (isCadastroEnviadoeSocial, schema) =>
      schema.test({
        message: 'Informe a Matrícula',
        test: (matricula: string) => isCadastroEnviadoeSocial || matricula,
      }),
    )
    .nullable(),
  dtAdmissao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Admissão')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data de Admissão deve está dentro da competência atual',
        (dt: string) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    )
    .nullable(),
  categoria: yup.string().required('Informe a Categoria'),
})

interface InitialFormProps {
  onCancel: () => void
  onSubmit: (dt: VinculoPartial) => void
}

export default function InitialForm(props: InitialFormProps) {
  const { onCancel, onSubmit } = props

  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [pessoaFisica, setPessoaFisica] = useState<PessoaFisica | null>(null)
  const [data, setData] = useState<VinculoPartial | null>(null)

  const { estabelecimento, empregador, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useVinculoObterDataInicial({
    estabelecimentoId: estabelecimento.id,
    vinculoTipo: VinculoTipoEnum.Funcionario_1,
    cpf: pessoaFisica?.nrInscricao || '',
    anoMes,
  })

  const isLoadingForm = isLoading || isFetching
  const isMatriculaSequencial =
    _data?.matricula?.indCadastroMatricula !== IndCadastroMatriculaEnum.Preencher_00
  const hasAdmissaoPreliminar = _data?.vinculo?.reciboAdmissaoPreliminar ? true : false

  useEffect(() => {
    if (!_data) return
    setData(_data.vinculo)
  }, [_data])

  async function handleSubmit() {
    if (!data) return
    setLoadingSubmit(true)
    try {
      data.matricula = (data?.matricula || '').trim()
      if (!hasAdmissaoPreliminar) {
        await verifyIfExistsMatricula(empregador.id, data.matricula)
      }
      onSubmit(data)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      context: {
        anoMes,
      },
      abortEarly: false,
    },
  })

  return (
    <Grid container spacing={2}>
      <Box width="100%" height={4}>
        {isLoadingForm && <LinearProgress />}
      </Box>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompletePessoaFisica
          required
          value={pessoaFisica?.id || ''}
          onChange={(e: FixLater, pessoaFisica: FixLater) => {
            setPessoaFisica(pessoaFisica || null)
            setData(null)
          }}
        />
      </Grid>

      {data && (
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <DatePickerNew
              label="Data Admissão"
              size="small"
              disabled={hasAdmissaoPreliminar}
              value={data.dtAdmissao || null}
              required
              validationErrors={validationErrors}
              name="dtAdmissao"
              onChange={(date) => {
                const dtAdmissao = date || undefined
                setData({ ...data, dtAdmissao, dtOpcaoFGTS: dtAdmissao })
              }}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MUIAutoComplete
              label="Categoria"
              options={categoriasFuncionarioFiltred}
              optionId="value"
              renderOption={(option: FixLater) => option.name}
              disabled={hasAdmissaoPreliminar}
              required
              name="categoria"
              validationErrors={validationErrors}
              value={data.categoria}
              onChange={(e: FixLater, obj: FixLater) => {
                const categoria = obj ? obj.value : ''
                setData((oldState) => ({
                  ...oldState,
                  categoria,
                }))
              }}
            />
          </Grid>

          {!hasAdmissaoPreliminar && !isMatriculaSequencial ? (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertContainer severity="info" title="Matrícula anterior do empregador">
                Matrícula anterior: <strong>{_data?.matricula?.ultimaMatricula}</strong>
              </AlertContainer>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Matrícula"
              fullWidth
              disabled={hasAdmissaoPreliminar || isMatriculaSequencial ? true : false}
              value={data?.matricula || ''}
              size="small"
              required={data.isCadastroEnviadoeSocial ? false : true}
              inputProps={{
                maxLength: 30,
              }}
              validationErrors={validationErrors}
              name="matricula"
              title={
                isMatriculaSequencial
                  ? 'A matrícula está configurada como sequêncial no empregador'
                  : ''
              }
              onChange={(e) => {
                const matricula = e?.target?.value || ''
                setData((oldState) => ({
                  ...oldState,
                  matricula,
                }))
              }}
            />
          </Grid>

          {hasAdmissaoPreliminar && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AlertContainer severity="info">
                Número do Recibo Admissão Preliminar: {data?.reciboAdmissaoPreliminar}
              </AlertContainer>
            </Grid>
          )}

          {hasAdmissaoPreliminar || isMatriculaSequencial ? (
            <></>
          ) : (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControlLabel
                label="Cadastro já enviado para o eSocial por outra contabilidade"
                control={
                  <Checkbox
                    checked={Boolean(data?.isCadastroEnviadoeSocial)}
                    onChange={(e) => {
                      const isCadastroEnviadoeSocial = e.target.checked
                      setData((oldState) => ({
                        ...oldState,
                        isCadastroEnviadoeSocial,
                      }))
                    }}
                    color="secondary"
                    size="small"
                  />
                }
              />
            </Grid>
          )}
        </>
      )}

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button
            isLoading={isLoadingSubmit}
            onClick={handleValidate}
            variant="contained"
            disabled={pessoaFisica ? false : true}
          >
            Continuar
          </Button>
        </ButtonBox>
      </Grid>
    </Grid>
  )
}
