import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@material-ui/core'
import { AlertProps } from '@material-ui/lab'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons'

interface ActionAccordionProps extends AlertProps {
  title: string
  children: React.ReactNode | string
  isOpen?: boolean
}

export default function ActionAccordion({
  isOpen: _open = true,
  title,
  children,
}: ActionAccordionProps) {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    setOpen(_open)
  }, [_open])

  return (
    <Accordion expanded={isOpen}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Typography
          color="textSecondary"
          style={{
            fontWeight: 'bold',
          }}
          variant="subtitle2"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
