import { useState, memo, useRef } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, Finder, PageHeader } from '~/components'
import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'

import useObterEmpregadoresParaFechamento from '~/hooks/queries/Competencia/useObterEmpregadoresParaFechamento'
import useProcessarFechamento from '~/hooks/queries/Competencia/useProcessarFechamento'
import useDialogNotification from '~/hooks/useDialogNotification'
import {
  FiltroCompetenciaFechamentoEnum,
  FiltroCompetenciaFechamentoValues,
} from '~/@types/enums/FiltroCompetenciaFechamentoEnum'

const MemoTable = memo(Table)

export default function FechamentoESocial() {
  const [query, setQuery] = useState('')
  const [dataForm, setDataForm] = useState<{
    grupoId: string | null
    filtroEmpresas: FiltroCompetenciaFechamentoEnum
  }>({
    grupoId: null,
    filtroEmpresas: FiltroCompetenciaFechamentoEnum.TodasEmpresas,
  })
  const tableRef = useRef<FixLater>(null)

  const { anoMes } = useAmbiente()
  const theme = useTheme()
  const dialogNotification = useDialogNotification()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterEmpregadoresParaFechamento({
    anoMes,
    filtroEmpresas: dataForm.filtroEmpresas,
    grupoId: dataForm.grupoId,
  })
  const data = _d || []
  const { mutateAsync, isLoading: isSubmitting } = useProcessarFechamento()

  async function handleProcess() {
    const rowsSelected: number[] = tableRef?.current?.state?.selectedRows?.data.map(
      (d: FixLater) => d.dataIndex,
    )

    if (rowsSelected.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe os Empregadores que deseja processar o Fechamento'],
      })
    }
    const competenciasEmpregadoresIds = rowsSelected.map((d) => data[d].id)
    await mutateAsync({ competenciasEmpregadoresIds })
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title="Fechamento eSocial">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} p={1} display="flex" gridGap={theme.spacing(2)}>
        <AutoCompleteGrupo
          value={dataForm.grupoId}
          onChange={(e, grupo) => {
            const grupoId = grupo?.id || null
            setDataForm((prev) => ({ ...prev, grupoId }))
          }}
        />
        <Box width={400}>
          <MUIAutoComplete
            label="Filtrar Empresas"
            options={FiltroCompetenciaFechamentoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={dataForm.filtroEmpresas}
            onChange={(e, obj) => {
              const filtroEmpresas = obj.value
              setDataForm((prev) => ({ ...prev, filtroEmpresas }))
            }}
          />
        </Box>
      </Box>

      <Box flex={1} position="relative" overflow="auto" minHeight={300}>
        <Box position="absolute" width="100%">
          <MemoTable
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            query={query}
            tableRef={tableRef}
          />
        </Box>
      </Box>

      <Box component={Paper} p={1} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleProcess} isLoading={isSubmitting}>
          Processar Fechamento
        </Button>
      </Box>
    </Box>
  )
}
