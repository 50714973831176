import React, { useState, useEffect, useCallback, memo } from 'react'

import { Box, Grid, Tooltip } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog, TimePicker } from '~/components'

import { DatePicker } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import TableDates from './components/TableDates'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import {
  IndConvocacaoIntermitenteValues,
  IndConvocacaoIntermitente,
} from '~/@types/enums/IndConvocacaoIntermitente'

import api from '~/services/api-pessoal'
import * as yup from 'yup'
import moment from 'moment'

const schema = yup.object().shape({
  dtInicio: yup
    .string()
    .required('Informe a Data de Início')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data informada deve está dentro da competência atual',
        (dt) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    )
    .nullable(),
  dtFim: yup
    .string()
    .required('Informe a Data Final')
    .nullable()
    .when(['dtInicio'], (dtInicio, schema, { value }) => {
      if (moment(dtInicio).isAfter(value)) {
        return schema
          .min(dtInicio, 'Data Final deve ser maior ou igual que a Data de Início')
          .nullable()
      }
    }),
  dtAceiteConvocacao: yup
    .string()
    .nullable()
    .when(['dtInicio'], (dtInicio, schema, { value }) => {
      if (moment(dtInicio).isBefore(value)) {
        return schema
          .min(dtInicio, 'Data da Confirmação deve ser menor ou igual que a Data de Início')
          .nullable()
      }
    }),
  indConvocacao: yup.string().required('Informe o Tipo de Convocação').nullable(),
  numeroHoras: yup
    .string()
    .when(['indConvocacao'], (indConvocacao, schema) =>
      parseInt(indConvocacao) === IndConvocacaoIntermitente.Horista_1
        ? schema.required('Informe o Número de Horas')
        : schema,
    )
    .nullable(),
})

const MemoTableDates = memo(TableDates)

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/VinculoIntermitente/${data?.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/VinculoIntermitente', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const diffDays = moment(data.dtFim).diff(data.dtInicio, 'days')

    if (diffDays > 365) return notification.error('O período informado ultrapasssa um Ano')

    if (data.id) {
      update()
      return
    }
    insert()

    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
    schemaOptions: {
      abortEarly: false,
      context: { anoMes },
    },
  })

  const handleDeleteDate = useCallback(
    async (id) => {
      const itens = data?.vinculoIntermitenteItens
      try {
        await api.delete(`VinculoIntermitenteItem/${id}`)
        const newItens = itens.filter((i) => i.id !== id)
        setData((oldState) => ({
          ...oldState,
          vinculoIntermitenteItens: newItens,
        }))
        notification.success('Data removida com sucesso')
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    },
    //eslint-disable-next-line
    [data.vinculoIntermitenteItens],
  )

  return (
    <ActionDialog
      title="Cadastro de Trabalho Intermitente"
      isOpen={isOpen}
      onClose={onClose}
      okLabel={data?.id ? 'Salvar' : 'Prosseguir'}
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            label="Tipo de Convocação"
            name="indConvocacao"
            options={IndConvocacaoIntermitenteValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            disabled={data?.id ? true : false}
            value={data?.indConvocacao || ''}
            onChange={(_, obj) => {
              const indConvocacao = obj?.value || ''
              setData((oldState) => ({
                ...oldState,
                indConvocacao,
                numeroHoras: null,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data de Início"
            size="small"
            required
            validationErrors={validationErrors}
            name="dtInicio"
            disabled={data?.id ? true : false}
            value={data?.dtInicio || null}
            onChange={(date) => {
              const dtInicio = date ? date.format('yyyy-MM-DD') : null
              const dtAceiteConvocacao = dtInicio
              setData((oldState) => ({
                ...oldState,
                dtInicio,
                dtAceiteConvocacao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data Final"
            size="small"
            required
            validationErrors={validationErrors}
            name="dtFim"
            disabled={data?.id ? true : false}
            value={data?.dtFim || null}
            onChange={(date) => {
              const dtFim = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtFim,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Tooltip
            arrow
            title={
              data?.indConvocacao !== IndConvocacaoIntermitente.Horista_1 && !data?.id
                ? 'Número de Horas só pode ser inserido caso o Tipo de Convocação seja Horista'
                : ''
            }
          >
            <Box>
              <TimePicker
                name="numeroHoras"
                label="Número de Horas"
                value={data?.numeroHoras || null}
                onChange={(horas) => {
                  const numeroHoras = horas

                  if (numeroHoras) {
                    const hours = numeroHoras.substring(0, 2)
                    const minutes = numeroHoras.substring(3, 5)
                    const time = hours + minutes
                    if (time >= '0800') {
                      dialogNotification.info({
                        descriptions: [
                          'As horas informadas ultrapassam 08:00 horas, essa informação está correta?',
                        ],
                        labelOnConfirm: 'Sim',
                      })
                    }
                  }

                  setData((oldState) => ({
                    ...oldState,
                    numeroHoras,
                  }))
                }}
                required={data?.indConvocacao === IndConvocacaoIntermitente.Horista_1}
                disabled={
                  data?.indConvocacao !== IndConvocacaoIntermitente.Horista_1 || data?.id
                    ? true
                    : false
                }
                validationErrors={validationErrors}
              />
            </Box>
          </Tooltip>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data da Confirmação"
            size="small"
            disabled={data?.id ? true : false}
            value={data?.dtAceiteConvocacao || null}
            name="dtAceiteConvocacao"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAceiteConvocacao = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({
                ...oldState,
                dtAceiteConvocacao,
              }))
            }}
          />
        </Grid>

        {data?.vinculoIntermitenteItens?.length > 0 && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MemoTableDates data={data.vinculoIntermitenteItens} onItemClick={handleDeleteDate} />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            variant="outlined"
            size="small"
            multiline
            inputProps={{
              maxLength: 200,
            }}
            disabled={data?.id ? true : false}
            value={data?.observacao || ''}
            onChange={(e) => {
              const observacao = e.target.value
              setData((oldState) => ({
                ...oldState,
                observacao,
              }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
