export enum VinculoTipoEnum {
  Funcionario_1 = 1,
  Empregador_2 = 2,
  Estagiario_3 = 3,
  Autonomo_4 = 4,
  Cooperado_5 = 5,
}

export const VinculoTipoValues = [
  { value: VinculoTipoEnum.Funcionario_1, name: 'Funcionário' },
  { value: VinculoTipoEnum.Empregador_2, name: 'Empregador' },
  { value: VinculoTipoEnum.Estagiario_3, name: 'Estagiário' },
  { value: VinculoTipoEnum.Autonomo_4, name: 'Autônomo' },
  { value: VinculoTipoEnum.Cooperado_5, name: 'Cooperado' },
]
