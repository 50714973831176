import { useEffect, useRef, useState } from 'react'

import * as yup from 'yup'
import {
  extractSelectedRowsTable,
  formatSimpleDate,
  getDateMaxAnoMes,
  getDateMinAnoMes,
} from '~/utils/utils'
import { formatToCPF } from 'brazilian-values'

import { Box, Grid, Paper } from '@material-ui/core'

import {
  PrintButton,
  Finder,
  DatePickerNew,
  Button,
  ContainerTable,
  Stack,
  ToolsDataTable,
  CopyLabel,
} from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterRelatoriosAdmissionais } from '~/hooks/queries/RelatorioAdmissional/useObterRelatoriosAdmissionais'
import { useGetByEstabelecimentoPeriodo } from '~/hooks/queries/VinculoConsulta/useGetByEstabelecimentoPeriodo'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { MUIDataTableColumnDef } from 'mui-datatables'

const schema = yup.object().shape({
  dtInicio: yup.date().typeError('Informe uma data válida').required('Informe a Data de Início'),
  dtFim: yup.date().typeError('Informe uma data válida').required('Informe a Data de Fim'),
})

export default function Impressao() {
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()

  const [dataForm, setDataForm] = useState({
    dtInicio: getDateMinAnoMes(anoMes),
    dtFim: getDateMaxAnoMes(anoMes),
  })
  const [query, setQuery] = useState('')

  const { mutateAsync, isLoading: isSubmitting } = useObterRelatoriosAdmissionais()

  const tableRef = useRef<FixLater>(null)

  useEffect(() => {
    setDataForm({
      dtInicio: getDateMinAnoMes(anoMes),
      dtFim: getDateMaxAnoMes(anoMes),
    })
  }, [anoMes])

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useGetByEstabelecimentoPeriodo({
    data: [VinculoTipoEnum.Funcionario_1],
    params: {
      estabelecimentoId: estabelecimento.id,
      dtInicio: dataForm.dtInicio,
      dtFim: dataForm.dtFim,
    },
  })
  const data = _d || []

  useEffect(() => {
    if (dataForm.dtInicio) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: () => refetch(),
    data: dataForm,
  })

  async function handlePrint() {
    const rowsSelecteds = extractSelectedRowsTable(tableRef)
    if (rowsSelecteds.length === 0) {
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário para prosseguir'],
      })
    }
    const funcionariosIds = rowsSelecteds.map((rowIndex) => data[rowIndex].id)
    await mutateAsync({
      data: funcionariosIds,
      params: {
        anoMes,
        estabelecimentoId: estabelecimento.id,
      },
    })
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Código',
      name: 'pessoaCodigo',
    },
    {
      label: 'CPF',
      name: 'pessoaCPF',
      options: {
        customBodyRender: (value) => <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel>,
      },
    },
    {
      label: 'Nome',
      name: 'pessoaNome',
    },
    {
      label: 'Admissão',
      name: 'dtAdmissao',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
  ]

  return (
    <Stack height="100%">
      <Stack component={Paper} p={1} orientation="horizontal" alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <DatePickerNew
              label="Data de Início Admissão"
              value={dataForm?.dtInicio || null}
              name="dtInicio"
              validationErrors={validationErrors}
              onChange={(date) => {
                const dtInicio = date || ''
                setDataForm((prev) => ({ ...prev, dtInicio }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DatePickerNew
              label="Data de Fim Admissão"
              value={dataForm?.dtFim || null}
              name="dtFim"
              validationErrors={validationErrors}
              onChange={(date) => {
                const dtFim = date || ''
                setDataForm((prev) => ({ ...prev, dtFim }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
              <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            </Box>
          </Grid>
        </Grid>
        <Button variant="contained" onClick={() => handleValidate(null)}>
          Buscar
        </Button>
      </Stack>

      <ContainerTable>
        <ToolsDataTable
          tableRef={tableRef}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          columns={columns}
          disableAutoHeight
          sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query }}
          optionsSelectable={{
            type: 'multiple',
          }}
        />
      </ContainerTable>

      <Stack p={1} component={Paper} orientation="horizontal" justifyContent="flex-end">
        <PrintButton onClick={handlePrint} isLoading={isSubmitting} />
      </Stack>
    </Stack>
  )
}
