import React, { useEffect, useState } from 'react'

import { useStepperContext } from '~/components/StepperForm'

import FeriasColetivaContent from './Content'

import { FeriasColetivaProvide, ParamsPageProps, ValuesMinProps } from './Context'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  FeriasColetivaAlertaDTO,
  FeriasColetivaConcedidaProgramadaDTO,
  FeriasColetivaDTO,
  FeriasColetivaRequestDTO,
  FeriasColetivaRequestDTOForm,
  obterFuncionariosAnalitico,
} from '~/hooks/queries/FeriasColetiva/obterFuncionariosAnalitico'
import { IndDiasFeriasProporcionaisRestantesEnum } from '~/@types/enums/IndDiasFeriasProporcionaisRestantesEnum'
import { processarFeriasColetiva } from '~/hooks/queries/FeriasColetiva/processarFeriasColetiva'

export default function FeriasColetiva() {
  const { estabelecimento, anoMes } = useAmbiente()
  const startData: FeriasColetivaRequestDTOForm = {
    estabelecimentoId: estabelecimento.id,
    setorId: null,
    nrDiasFerias: 0,
    dtInicioFerias: null,
    dtFimFerias: null,
    nrDiasAbono: 0,
    dtInicioAbono: null,
    dtFimAbono: null,
    dtPagamentoFerias: null,
    dtAvisoFerias: null,
    indDiasFeriasProporcionaisRestantes:
      IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Ferias_Atual_1,
  }

  const [collection, setCollection] = useState<FeriasColetivaDTO[]>([])
  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [paramsPage, setParamsPage] = useState<ParamsPageProps>({
    isAbono: false,
    isFilterBySetor: false,
  })
  const [isOpenCollapseForm, setOpenCollapseForm] = useState(true)
  const [dataForm, setDataForm] = useState<FeriasColetivaRequestDTOForm>(startData)
  const [valuesMin, setValuesMin] = useState<ValuesMinProps>({
    dtAvisoFerias: null,
    dtPagamentoFerias: null,
  })

  const [isLoadingSearch, setLoadingSearch] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const [query, setQuery] = useState('')

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const {
    close: closeDialogAlerts,
    data: dataDialogAlerts,
    isOpen: isOpenDialogAlerts,
    open: openDialogAlerts,
  } = useDialog<FeriasColetivaAlertaDTO[]>([])
  const {
    close: closeDialogConcedidaProgramada,
    data: dataDialogConcedidaProgramada,
    isOpen: isOpenDialogConcedidaProgramada,
    open: openDialogConcedidaProgramada,
  } = useDialog<{ title: string; data: FeriasColetivaConcedidaProgramadaDTO[] }>({
    title: 'Férias',
    data: [],
  })
  const {
    close: closeDialogLicencaRemunerada,
    data: dataDialogLicencaRemunerada,
    isOpen: isOpenDialogLicencaRemunerada,
    open: openDialogLicencaRemunerada,
  } = useDialog<FeriasColetivaDTO | null>(null)
  const { setStepCurrent } = useStepperContext()

  useEffect(() => {
    setCollection([])
    setRowsSelected([])
  }, [dataForm])

  useEffect(() => {
    setDataForm(startData)
    // eslint-disable-next-line
  }, [anoMes, estabelecimento])

  function onChangeAbono(checked: boolean) {
    setParamsPage((prev) => ({ ...prev, isAbono: checked }))
    setDataForm((prev) => ({ ...prev, nrDiasAbono: 0, dtInicioAbono: null, dtFimAbono: null }))
  }

  function onChangeFilterSetor(checked: boolean) {
    setParamsPage((prev) => ({ ...prev, isFilterBySetor: checked }))
    setDataForm((prev) => ({ ...prev, setorId: null }))
  }

  function onCancel() {
    setDataForm(startData)
  }

  async function onSearch(data: FeriasColetivaRequestDTO) {
    setLoadingSearch(true)
    try {
      const response = await obterFuncionariosAnalitico(data)
      setCollection(response)
      setRowsSelected([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSearch(false)
    }
  }

  async function onProcess() {
    setSubmitting(true)
    try {
      const funcionariosIds = rowsSelected.map((index) => collection[index].funcionarioId)
      await processarFeriasColetiva({
        funcionariosIds,
        dadosRequisicao: dataForm as FeriasColetivaRequestDTO,
      })
      notification.success('Férias Coletivas criadas com Sucesso')
      setStepCurrent((step) => step + 1)
      setCollection([])
      setRowsSelected([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <FeriasColetivaProvide
      value={{
        formProps: {
          onChangeAbono,
          onChangeFilterSetor,
          paramsPage,
          isOpenCollapseForm,
          setOpenCollapseForm,
          onCancel,
          dataForm,
          setDataForm,
          valuesMin,
          setValuesMin,
        },
        tableProps: {
          query,
          setQuery,
          collection,
          rowsSelected,
          setRowsSelected,
        },
        dialogsProps: {
          openDialogAlerts,
          isOpenDialogAlerts,
          dataDialogAlerts,
          closeDialogAlerts,

          openDialogConcedidaProgramada,
          isOpenDialogConcedidaProgramada,
          dataDialogConcedidaProgramada,
          closeDialogConcedidaProgramada,

          openDialogLicencaRemunerada,
          isOpenDialogLicencaRemunerada,
          dataDialogLicencaRemunerada,
          closeDialogLicencaRemunerada,
        },
        requestProps: {
          onSearch,
          onProcess,
          isLoadingSearch,
          isSubmitting,
        },
      }}
    >
      <FeriasColetivaContent />
    </FeriasColetivaProvide>
  )
}
