import React from 'react'
import { Box, Typography, Divider, IconButton, makeStyles } from '@material-ui/core'
import { ArrowBack as BackIcon } from '@material-ui/icons'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  boxTitleSubtitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'block',
    color: '#666',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    color: '#AAA',
  },
  divider: {
    display: 'block',
    height: 2,
    width: '100%',
    background: '#DDD',
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(2),
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightContent: {},
}))

export default function PageHeader(props) {
  const classes = useStyles()

  const { title, subtitle, children, onBack, renderRight } = props

  return (
    <Box>
      <Box className={classes.box}>
        <Box className={classes.headerContent}>
          <Box className={classes.leftContent}>
            {onBack && (
              <Box>
                <IconButton size="small" onClick={onBack}>
                  <BackIcon color="primary" />
                </IconButton>
              </Box>
            )}

            <Box className={classes.boxTitleSubtitle}>
              <Typography className={classes.title}>{title}</Typography>
              {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
            </Box>
          </Box>

          {renderRight && <Box className={classes.rightContent}>{renderRight}</Box>}
        </Box>
        {children}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  )
}

PageHeader.defaultProps = {
  renderRight: false,
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onBack: PropTypes.func,
  renderRight: PropTypes.bool,
}
