import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import * as yup from 'yup'

import { Box, Grid, Paper } from '@material-ui/core'
import { Finder } from 'mio-library-ui'

import { AnoMesTextField, MultipleSelect, PageHeader, PDFViewer, PrintButton } from '~/components'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'
import useValidationErrors from '~/hooks/useValidationErrors'

const recibosIdfsRelacaoBancaria = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Rescisao_5,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.Socio_15,
  ReciboIDFEnum.Autonomo_16,
  ReciboIDFEnum.Cooperado_19,
]

const recibosIdfsInicializarFormConsts = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Rescisao_5,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Socio_15,
  ReciboIDFEnum.Autonomo_16,
]

const recibosIdfsInicializarForm = ReciboIDFValues.filter((idr) =>
  recibosIdfsInicializarFormConsts.includes(idr.value),
)
const recibosIdfs = ReciboIDFValues.filter((idr) => recibosIdfsRelacaoBancaria.includes(idr.value))

const schema = yup.object().shape({
  anoMesInicio: yup.string().required('Informe o Ano/Mês Início'),
  anoMesFim: yup.string().required('Informe o Ano/Mês Fim'),
  identificacaoRecibo: yup
    .array()
    .min(1, 'Informe pelo menos uma Identificação dos Recibos para Impressão'),
})

export default function RelatorioFolhaColetiva() {
  const [collection, setCollection] = useState([])
  const [rowsSelecteds, setRowsSelecteds] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState({
    anoMesInicio: '',
    anoMesFim: '',
    identificacaoRecibo: [],
  })

  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { anoMes, estabelecimento } = useAmbiente()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: handlePrint,
  })

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/EstabelecimentoConsulta')
        setCollection(response?.data?.data || [])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setData({
      anoMesInicio: anoMes,
      anoMesFim: anoMes,
      identificacaoRecibo: recibosIdfsInicializarForm,
    })
    // eslint-disable-next-line
  }, [])

  function handlePrint() {
    if (!(rowsSelecteds.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um estabelecimento'],
      })
    const estabelecimentosIds = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
    const idfsRecibos = data.identificacaoRecibo.map((obj) => obj.value)
    openPDFViewer({
      estabelecimentosIds,
      idfsRecibos,
      anoMesInicio: data.anoMesInicio,
      anoMesFim: data.anoMesFim,
    })
    setRowsSelecteds([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Folha Coletiva">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box component={Paper} padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <MultipleSelect
              inputProps={{
                label: 'Identificação dos Recibos para Impressão',
              }}
              value={data.identificacaoRecibo}
              options={recibosIdfs}
              getOptionLabel={(option) => option.name}
              onChange={(e, options) =>
                setData((prev) => ({
                  ...prev,
                  identificacaoRecibo: options,
                }))
              }
              limitTags={5}
              required
              validationErrors={validationErrors}
              name="identificacaoRecibo"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AnoMesTextField
              label="Ano/Mês Início"
              value={data.anoMesInicio}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesInicio: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesInicio"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AnoMesTextField
              label="Ano/Mês Fim"
              value={data.anoMesFim}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  anoMesFim: e?.target?.value?.replace('/', '') || '',
                }))
              }
              required
              validationErrors={validationErrors}
              name="anoMesFim"
            />
          </Grid>
        </Grid>
      </Box>
      <Table
        data={collection}
        query={query}
        isLoading={isLoading}
        rowsSelected={rowsSelecteds}
        handleRowSelected={setRowsSelecteds}
      />
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="FolhaColetiva"
        title="Folha Coletiva"
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/FolhaColetiva/ObterRelatorio',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <PrintButton onClick={handleValidate} />
      </Box>
    </Box>
  )
}
