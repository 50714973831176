import { useState, useCallback, memo } from 'react'

import { useQueryClient } from 'react-query'
import { queryGetVinculosMenu2 } from '~/hooks/queries/RP/useGetVinculosMenu2'

import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import { PageHeader } from 'mio-library-ui'

import { ConfirmDeleteDialog } from '~/components'

import Header from './components/Header'
import Footer from './components/Footer'
import Menu, { DECIMO_TERCEIRO_SALARIO, TODOS } from './components/Menu'
import Table from './components/Table'
import DynamicMenu from './components/DynamicMenu'

import ReciboFeriasForm from './components/Menu/components/ReciboFeriasForm'
import ReciboFeriasFormNew from './components/Menu/components/ReciboFeriasFormNew'
import RecibosFeriasErro from './components/Menu/components/RecibosFeriasErro'
import ReciboRescisao from './components/Menu/components/ReciboRescisao'
import ReciboAutonomo from './components/Menu/components/ReciboAutonomo'
import ReciboIntermitente from './components/Menu/components/ReciboIntermitente'
import DialogComplemento from './components/Table/components/DialogComplemento'

import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'
import useDialog from '~/hooks/useDialog'

import { useContextRecibo } from '~/hooks/useRecibo'

import api from '~/services/api-pessoal'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { formatAnoMes, haveTabelaMesInAnoMes } from '~/hooks/useUtils'
import DialogTabelaMes from '~/components/DialogTabelaMes'

import ContainerEvento from './components/ContainerEvento'
import RecibosList from './components/Menu/components/RecibosList'
import DialogAlertasFerias from './components/DynamicMenu/components/DialogAlertasFerias'
import DialogLembretes from './components/DynamicMenu/components/DialogLembretes'

import { rpVerificarExisteRecibosMes } from '~/hooks/queries/RP/rpVerificarExisteRecibosMes'
import { useObterRecibosPosterioresIguaisCompetencia } from '~/hooks/queries/RP/useObterRecibosPosterioresIguaisCompetencia'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

const MemoTable = memo(Table)
const MemoHeader = memo(Header)

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(1),
  },
  content: {
    height: '100%',
  },
  maxHeightContainer: {
    maxHeight: '100%',
  },
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  isLoadingSeparation: {
    height: theme.spacing(0.5),
    width: '100%',
    backgroundColor: theme.palette.grey[400],
  },
}))

const ReciboPagamento = () => {
  const {
    reciboPagamento,
    setReciboPagamento,
    isLoadingRecibo,
    setLoadingRecibo,
    expanded,
    setExpanded,
    titlePage,
    vinculoSelected,
    setVinculoSelected,
    isOpenDialogLembretes,
    setOpenDialogLembretes,
  } = useContextRecibo()
  const queryClient = useQueryClient()

  const [loadingOpenEvent, setLoadingOpenEvent] = useState(false)
  const [dataFormEvento, setDataFormEvento] = useState({})
  const {
    close: closeDialogEvento,
    open: openDialogEvento,
    data: dataDialogEvento,
    isOpen: isOpenDialogEvento,
  } = useDialog()

  const {
    close: closeDialogFeriasErro,
    open: openDialogFeriasErro,
    data: dataDialogFeriasErro,
    isOpen: isOpenDialogFeriasErro,
  } = useDialog({ recibos: [], msg: '', vinculo: {}, lotacaoId: '' })

  const {
    close: closeFormReciboRescisao,
    open: openFormReciboRescisao,
    data: dataFormReciboRescisao,
    isOpen: isOpenFormReciboRescisao,
  } = useDialog(null)

  const {
    close: closeReciboIntermitente,
    open: openReciboIntermitente,
    data: dataReciboIntermitente,
    isOpen: isOpenReciboIntermitente,
  } = useDialog(null)

  const {
    close: closeDialogComplemento,
    data: dataDialogComplemento,
    isOpen: isOpenDialogComplemento,
    open: openDialogComplemento,
  } = useDialog(null)

  const {
    close: closeReciboList,
    open: openReciboList,
    data: dataReciboList,
    isOpen: isOpenReciboList,
  } = useDialog({
    vinculo: null,
    rps: [],
  })

  const {
    close: closeDialogTabelaMes,
    open: openDialogTabelaMes,
    isOpen: isOpenDialogTabelaMes,
  } = useDialog()

  const [reciboFeriasForm, setReciboFeriasForm] = useState({
    isOpen: false,
    data: {},
    vinculo: {},
  })
  const [formReciboAutonomo, setFormReciboAutonomo] = useState({
    isOpen: false,
    vinculo: {},
    lotacaoId: '',
  })
  const [confirmDeleteEvento, setConfirmDeleteEvento] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })
  const [confirmDeleteReciboPagamento, setConfirmDeleteReciboPagamento] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const classes = useStyles()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes, mes } = useAmbiente()
  const {
    close: closeFormFerias,
    data: dataFormFerias,
    isOpen: isOpenFormFerias,
    open: openFormFerias,
  } = useDialog(null)
  const {
    mutateAsync: mutateAsyncObterRecibosPosterioresIguaisCompetencia,
    isLoading: isLoadingObterRecibosPosterioresIguaisCompetencia,
  } = useObterRecibosPosterioresIguaisCompetencia()

  const url = window.location.href
  const params = url.split('/')
  const ultimoParametro = params[params.length - 1]

  const handleSelectItem = useCallback(async (evento, value) => {
    const handleSelectRecibo = async (reciboId) => {
      setLoadingRecibo(true)
      try {
        const response = await api.get(`RP/${reciboId}`)
        const dt = response.data.data
        setVinculoSelected(dt.vinculoId)
        setReciboPagamento(dt)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingRecibo(false)
      }
    }

    const functionsMap = {
      reciboSelecionado: handleSelectRecibo,
    }

    functionsMap[evento](value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterSubmitEvento = useCallback(() => {
    handleSelectItem('reciboSelecionado', reciboPagamento?.id)
    //eslint-disable-next-line
  }, [reciboPagamento])

  const handleAfterDeleteRecibo = () => {
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    setReciboPagamento(null)
    handleCloseConfirmDeleteReciboPgamento()
  }

  const excluirEvento = useCallback(async () => {
    setConfirmDeleteEvento((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(
        `/rp/RemoverEvento?rpId=${reciboPagamento?.id}&rpItemId=${confirmDeleteEvento?.id}`,
      )
      handleAfterSubmitEvento()
      notification.remove(4000, 'top-right')
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setConfirmDeleteEvento((oldState) => ({
      ...oldState,
      isDeleting: false,
      isOpen: false,
    }))
    //eslint-disable-next-line
  }, [confirmDeleteEvento, reciboPagamento])

  const handleCloseConfirmDeleteEvento = () => {
    setConfirmDeleteEvento((oldState) => ({
      ...oldState,
      isOpen: false,
      id: null,
    }))
  }

  const handleOpenCadastroEvent = async (id) => {
    setLoadingOpenEvent(true)
    try {
      const response = await api.get(`/Evento/${id}`)
      openDialogEvento(response?.data?.data || {})
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingOpenEvent(false)
    }
  }

  const handleClickItemEvento = useCallback(
    (event, data) => {
      const handleEditEvento = (evento) => {
        setDataFormEvento(evento)
      }

      const handleComplemento = (data) => {
        openDialogComplemento(data)
      }

      const handleDeleteEvento = (id) => {
        setConfirmDeleteEvento((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        edit: handleEditEvento,
        delete: handleDeleteEvento,
        formEvent: handleOpenCadastroEvent,
        complemento: handleComplemento,
      }
      functions[event](data)
    },
    // eslint-disable-next-line
    [reciboPagamento],
  )

  const adicionarRecibo = async (vinculoId, idf, lotacaoId) => {
    setLoadingRecibo(true)
    try {
      const response = await api.post('RP/Novo', null, {
        params: {
          vinculoId,
          idf,
          anoMes,
          lotacaoId,
        },
      })
      let id = ''
      if (response?.data?.data) id = response.data.data?.id
      queryClient.invalidateQueries(queryGetVinculosMenu2)
      handleSelectItem('reciboSelecionado', id)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingRecibo(false)
    }
  }

  const handleAfterAddReciboFerias = (reciboId) => {
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    handleSelectItem('reciboSelecionado', reciboId)
    handleCloseReciboFeriasForm()
  }

  const handleAfterAddReciboRescisao = (reciboId) => {
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    handleSelectItem('reciboSelecionado', reciboId)
    closeFormReciboRescisao()
  }

  function handleAfterAddReciboAutonomo(reciboId) {
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    handleSelectItem('reciboSelecionado', reciboId)
    handleCloseFormReciboAutonomo()
  }

  const handleAfterAddReciboIntermitente = (rpId) => {
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    handleSelectItem('reciboSelecionado', rpId)
    closeReciboIntermitente()
  }

  const obterVinculoFerias = useCallback(
    async (vinculo, lotacaoId = '') => {
      try {
        const response = await api.get(
          `RP/GetVinculoFerias?vinculoId=${vinculo.vinculoId}&anoMes=${anoMes}`,
          {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
          },
        )

        const data = response.data.data

        if (data.isRPLotacao && lotacaoId && data.feriasAtual) {
          data.feriasAtual.lotacaoId = lotacaoId
        }
        setReciboFeriasForm({
          isOpen: true,
          data,
          vinculo,
        })
      } catch (err) {
        const { response } = err
        if (response.status === 300) {
          const { data } = response
          openDialogFeriasErro({
            recibos: data.data,
            msg: data.errors[0],
            vinculo,
            lotacaoId,
          })
          return
        }

        const msg = String(
          err.response.data.errors || 'Ocorreu um erro ao tentar processar a requisição',
        )
        openDialogFeriasErro({
          recibos: [],
          msg: msg,
          vinculo,
          lotacaoId,
        })
      }
    },
    [anoMes, openDialogFeriasErro],
  )

  const handleContextMenuClickItem = useCallback(
    async (event, value) => {
      setLoadingRecibo(true)
      const haveTabelaMes = await haveTabelaMesInAnoMes(anoMes)

      if (!haveTabelaMes) {
        setLoadingRecibo(false)
        throw dialogNotification.warning({
          descriptions: ['Não foi encontrado a Tabela Mês para a competência atual'],
          onConfirm: () => openDialogTabelaMes(),
          labelOnConfirm: 'Abrir Tabela Mês',
        })
      }
      setLoadingRecibo(false)

      function handleAddReciboTodosSingle({ vinculo, lotacaoId }) {
        adicionarRecibo(vinculo.vinculoId, ReciboIDFEnum.Autonomo_16, lotacaoId)
      }

      function handleAddRecibo13Single({ vinculo, lotacaoId }) {
        let decimoTerceiro = ReciboIDFEnum.DecimoTerceiro1aParcela_7
        if (mes === '12') decimoTerceiro = ReciboIDFEnum.DecimoTerceiro2aParcela_8
        adicionarRecibo(vinculo.vinculoId, decimoTerceiro, lotacaoId)
      }

      function handleAddRecibo({ vinculo, lotacaoId, idf }) {
        adicionarRecibo(vinculo.vinculoId, idf, lotacaoId)
      }

      function handleVerifyExistRecibosFerias(props) {
        const recibos = props?.vinculo?.recibos || []
        const reciboId = recibos.find((d) => d.idf === ReciboIDFEnum.Ferias_2)?.reciboId || null
        if (reciboId) {
          dialogNotification.info({
            descriptions: [
              'Foram localizados recibos de férias referentes ao funcionário. Como deseja proceder?',
            ],
            onConfirm: () => handleSelectItem('reciboSelecionado', reciboId),
            onCancel: () => handleOpenFormReciboFerias(props),
            labelOnConfirm: 'Acessar recibo existente',
            labelOnCancel: 'Criar novo recibo',
          })
        } else {
          handleOpenFormReciboFerias(props)
        }
      }

      const handleOpenFormReciboFerias = async ({ vinculo, lotacaoId }) => {
        try {
          const response = await rpVerificarExisteRecibosMes(vinculo.vinculoId, anoMes)
          if (response) {
            openDialogFeriasErro({
              recibos: response.recibos,
              msg: response.mensagem,
              vinculo,
              lotacaoId,
            })
          } else {
            openFormFerias(vinculo)
          }
        } catch (err) {
          const msg = String(
            err.response.data.errors || 'Ocorreu um erro ao tentar processar a requisição',
          )
          openDialogFeriasErro({
            recibos: [],
            msg: msg,
            vinculo,
            lotacaoId,
          })
        }
      }

      const handleOpenFormReciboRescisao = async ({ vinculo }) => {
        const vinculoId = vinculo?.vinculoId
        const rps = await mutateAsyncObterRecibosPosterioresIguaisCompetencia({
          vinculoId,
          competencia: anoMes,
        })
        if (rps.length > 0) {
          openReciboList({
            rps,
            vinculo,
          })
        } else {
          openFormReciboRescisao(vinculoId)
        }
      }

      function handleAddReciboAutonomo({ vinculo, lotacaoId }) {
        setFormReciboAutonomo({
          isOpen: true,
          lotacaoId,
          vinculo,
        })
      }

      function handleAddReciboIntermitente({ vinculo }) {
        openReciboIntermitente(vinculo)
      }

      function expandNewRecibo({ vinculo }) {
        const newExpanded = expanded
        const itemExpanded = `1|${vinculo.vinculoId}`

        newExpanded[1] = itemExpanded
        setExpanded(newExpanded)
      }

      expandNewRecibo(value)

      const functionsMap = {
        [TODOS]: handleAddReciboTodosSingle,
        [DECIMO_TERCEIRO_SALARIO]: handleAddRecibo13Single,
        [ReciboIDFEnum.Mensal_1]: handleAddRecibo,
        [ReciboIDFEnum.Ferias_2]: handleVerifyExistRecibosFerias,
        [ReciboIDFEnum.Complementar_3]: handleAddRecibo,
        [ReciboIDFEnum.Adiantamento_4]: handleAddRecibo,
        [ReciboIDFEnum.Rescisao_5]: handleOpenFormReciboRescisao,
        [ReciboIDFEnum.RescisaoComplementar_6]: handleAddRecibo,
        [ReciboIDFEnum.DecimoTerceiro1aParcela_7]: handleAddRecibo,
        [ReciboIDFEnum.DecimoTerceiro2aParcela_8]: handleAddRecibo,
        [ReciboIDFEnum.PrimeiraQuinzena_9]: handleAddRecibo,
        [ReciboIDFEnum.SegundaQuinzena_10]: handleAddRecibo,
        [ReciboIDFEnum.RescisaoSimulada_11]: handleAddRecibo,
        [ReciboIDFEnum.Resilicao_14]: handleAddRecibo,
        [ReciboIDFEnum.Socio_15]: handleAddRecibo,
        [ReciboIDFEnum.Autonomo_16]: handleAddReciboAutonomo,
        [ReciboIDFEnum.ConvencaoColetiva_18]: handleAddRecibo,
        [CategoriaEnum.Empregado_Intermitente_111]: handleAddReciboIntermitente,
      }
      functionsMap[event](value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mes,
      openFormFerias,
      openDialogFeriasErro,
      openFormReciboRescisao,
      adicionarRecibo,
      expanded,
      setExpanded,
      anoMes,
      dialogNotification,
      openDialogTabelaMes,
      setLoadingRecibo,
    ],
  )

  const handleDeleteReciboPagamento = useCallback(async () => {
    setConfirmDeleteReciboPagamento((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`RP/${confirmDeleteReciboPagamento?.id}`)
      handleAfterDeleteRecibo()
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setConfirmDeleteReciboPagamento((oldState) => ({
      ...oldState,
      isDeleting: false,
    }))
    //eslint-disable-next-line
  }, [confirmDeleteReciboPagamento, handleAfterDeleteRecibo])

  const handleOpenConfirmaDeleteReciboPagamento = useCallback((id) => {
    setConfirmDeleteReciboPagamento((oldState) => ({
      ...oldState,
      isOpen: true,
      id,
    }))
  }, [])

  const handleAfterSubmitCadastroEvento = () => {
    closeDialogEvento()
    handleAfterSubmitEvento()
  }

  const handleCloseConfirmDeleteReciboPgamento = useCallback(() => {
    setConfirmDeleteReciboPagamento((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
      id: null,
    }))
  }, [])

  const handleAfterDeleteRecibosInFerias = (vinculo) => {
    closeDialogFeriasErro()
    openFormFerias(vinculo)
    queryClient.invalidateQueries(queryGetVinculosMenu2)
  }

  const handleCloseReciboFeriasForm = () => {
    setReciboFeriasForm({
      isOpen: false,
      data: {},
      vinculo: {},
    })
  }

  function handleCloseFormReciboAutonomo() {
    setFormReciboAutonomo({
      lotacaoId: '',
      vinculo: {},
      isOpen: false,
    })
  }

  function handleChangeOldFormFerias(vinculo) {
    obterVinculoFerias(vinculo)
    closeFormFerias()
  }

  function handleAfterSubmitReciboListRescisao(vinculo) {
    closeReciboList()
    queryClient.invalidateQueries(queryGetVinculosMenu2)
    setReciboPagamento(null)
    handleContextMenuClickItem(ReciboIDFEnum.Rescisao_5, { vinculo })
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} className={classes.content}>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={3} className={classes.maxHeightContainer}>
          <Menu
            onSelectItem={handleSelectItem}
            onContextMenuItemClick={handleContextMenuClickItem}
            param={ultimoParametro}
          />
        </Grid>
        <Grid item xs={6} sm={7} md={8} lg={9} xl={9} className={classes.maxHeightContainer}>
          <Box className={classes.main}>
            <PageHeader
              title={titlePage}
              renderRight={
                !isLoadingRecibo && reciboPagamento ? (
                  <DynamicMenu
                    handleOpenConfirmaDeleteReciboPagamento={
                      handleOpenConfirmaDeleteReciboPagamento
                    }
                    onHandleSelectItem={handleSelectItem}
                  />
                ) : (
                  <></>
                )
              }
            />

            <MemoHeader />

            <Box className={classes.isLoadingSeparation}>
              {loadingOpenEvent || isLoadingObterRecibosPosterioresIguaisCompetencia ? (
                <LinearProgress />
              ) : (
                <></>
              )}
            </Box>

            <Box flex={1} overflow="auto" minHeight={250}>
              <MemoTable onItemClick={handleClickItemEvento} />
            </Box>

            <Footer formEvento={dataFormEvento} onAfterSubmitEvento={handleAfterSubmitEvento} />
          </Box>
        </Grid>
      </Grid>

      <ReciboFeriasForm
        isOpen={reciboFeriasForm.isOpen}
        onClose={handleCloseReciboFeriasForm}
        data={reciboFeriasForm.data}
        vinculo={reciboFeriasForm.vinculo}
        onAfterAddReciboFerias={handleAfterAddReciboFerias}
      />
      {dataFormFerias && (
        <ReciboFeriasFormNew
          isOpen={isOpenFormFerias}
          onClose={closeFormFerias}
          vinculo={dataFormFerias}
          changeOldVersion={handleChangeOldFormFerias}
          onAfterAddReciboFerias={handleAfterAddReciboFerias}
        />
      )}

      <RecibosFeriasErro
        isOpen={isOpenDialogFeriasErro}
        onClose={closeDialogFeriasErro}
        data={dataDialogFeriasErro}
        onAfterDeleteRecibos={handleAfterDeleteRecibosInFerias}
      />

      {isOpenReciboList && (
        <RecibosList
          isOpen={isOpenReciboList}
          onClose={closeReciboList}
          data={dataReciboList.rps}
          vinculo={dataReciboList.vinculo}
          message={`Para criar um Recibo de Rescisão é necessário excluir os Recibos posteriores a Competência Atual (${formatAnoMes(
            anoMes,
          )})`}
          onAfterSubmit={handleAfterSubmitReciboListRescisao}
        />
      )}

      {dataFormReciboRescisao && (
        <ReciboRescisao
          isOpen={isOpenFormReciboRescisao}
          onClose={closeFormReciboRescisao}
          vinculoId={dataFormReciboRescisao}
          onAfterAddReciboRescisao={handleAfterAddReciboRescisao}
        />
      )}

      {formReciboAutonomo?.vinculo && (
        <ReciboAutonomo
          isOpen={formReciboAutonomo.isOpen}
          onClose={handleCloseFormReciboAutonomo}
          vinculo={formReciboAutonomo.vinculo}
          lotacaoId={formReciboAutonomo.lotacaoId}
          onAfterSubmit={handleAfterAddReciboAutonomo}
        />
      )}

      {isOpenReciboIntermitente && (
        <ReciboIntermitente
          isOpen={isOpenReciboIntermitente}
          onClose={closeReciboIntermitente}
          vinculo={dataReciboIntermitente}
          onAfterSubmitForm={handleAfterAddReciboIntermitente}
        />
      )}

      {dataDialogEvento && (
        <ContainerEvento
          isOpen={isOpenDialogEvento}
          onClose={closeDialogEvento}
          data={dataDialogEvento}
          onAfterSubmitForm={handleAfterSubmitCadastroEvento}
        />
      )}

      {isOpenDialogComplemento && (
        <DialogComplemento
          isOpen={isOpenDialogComplemento}
          onClose={closeDialogComplemento}
          data={dataDialogComplemento}
          onAfterSubmit={handleAfterSubmitEvento}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={confirmDeleteEvento.isOpen}
        isDeleting={confirmDeleteEvento.isDeleting}
        onClose={handleCloseConfirmDeleteEvento}
        onCancel={handleCloseConfirmDeleteEvento}
        onConfirm={excluirEvento}
        description="Você quer mesmo excluir este evento?"
      />

      <ConfirmDeleteDialog
        isOpen={confirmDeleteReciboPagamento.isOpen}
        isDeleting={confirmDeleteReciboPagamento.isDeleting}
        onClose={handleCloseConfirmDeleteReciboPgamento}
        onCancel={handleCloseConfirmDeleteReciboPgamento}
        onConfirm={handleDeleteReciboPagamento}
      />

      <DialogTabelaMes isOpen={isOpenDialogTabelaMes} onClose={closeDialogTabelaMes} />

      {reciboPagamento?.idf === ReciboIDFEnum.Mensal_1 && (
        <DialogAlertasFerias vinculoId={vinculoSelected} rpIdf={reciboPagamento?.idf} />
      )}

      <DialogLembretes
        isOpen={isOpenDialogLembretes}
        onClose={() => setOpenDialogLembretes(false)}
        openDialog={() => setOpenDialogLembretes(true)}
        vinculoId={vinculoSelected}
      />
    </Box>
  )
}

export default ReciboPagamento
