import React, { useState, useEffect, useCallback } from 'react'

import api from '~/services/api-pessoal'
import PropTypes from 'prop-types'

import { Box, Dialog, Slide, IconButton, CircularProgress, useTheme, Grid } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { PageHeader, Button } from '../'

import ReporterSelector from '../ReportViewer/components/ReporterSelector'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useResize from '~/hooks/useResize'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PDFViewer(props) {
  const {
    title,
    subtitle,
    isOpen,
    onClose,
    reportKey,
    axiosConfig,
    isSalvarDocumento = false,
  } = props

  const [pdfUrl, setPdfUrl] = useState(null)
  const [report, setReport] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSave, setLoadingSave] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { height, width } = useResize()
  const theme = useTheme()

  const handleRequest = useCallback(
    async (isSalvarDocumento) => {
      return await api({
        headers: {
          'DC-PrintMode': 'PDF',
          'DC-PrintRelatorioId': report.id,
        },
        responseType: 'blob',
        ...axiosConfig,
        params: {
          ...axiosConfig.params,
          isSalvarDocumento,
        },
      })
    },
    [axiosConfig, report],
  )

  useEffect(() => {
    async function getURLPdf() {
      if (!isOpen) return
      if (!report?.id) return
      setLoading(true)
      try {
        const response = await handleRequest(false)

        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        setPdfUrl(fileURL)
      } catch (err) {
        if (err?.response?.data) err.response.data = JSON.parse(await err?.response?.data.text())
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getURLPdf()
    // eslint-disable-next-line
  }, [isOpen, report, handleRequest])

  function handleClose() {
    setReport({})
    setPdfUrl(null)
    onClose()
  }

  async function handleClickSave() {
    setLoadingSave(true)
    try {
      await handleRequest(true)
      notification.success('Documento salvo com sucesso')
    } catch (error) {
      notification.error('Não foi possível salvar o Documento')
    } finally {
      setLoadingSave(false)
    }
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <Box padding={2}>
        <Box position="absolute" right={theme.spacing(2)} top={theme.spacing(2)}>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <PageHeader title={title} subtitle={subtitle}>
          <Box marginRight={6} width="100%">
            <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
              {isSalvarDocumento && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button isLoading={isLoadingSave} onClick={handleClickSave}>
                    Salvar Documento
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={4}>
                <ReporterSelector reportKey={reportKey} value={report} onChange={setReport} />
              </Grid>
            </Grid>
          </Box>
        </PageHeader>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
          >
            <CircularProgress size={75} />
          </Box>
        ) : (
          <iframe title={title} width={width - 30} height={height - 150} src={pdfUrl} />
        )}
      </Box>
    </Dialog>
  )
}

PDFViewer.defaultProps = {
  title: 'Relatório',
  isOpen: false,
}

PDFViewer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  isSalvarDocumento: PropTypes.bool,
  onClose: PropTypes.func,
  reportKey: PropTypes.string,
  axiosConfig: PropTypes.object,
}
