import { useEffect, useState } from 'react'

import { Paper } from '@material-ui/core'

import { Finder, Button, Stack, ContainerTable } from '~/components'

import Table from './Table'

import useAmbiente from '~/hooks/useAmbiente'

import { useVinculoConsultaGetVinculosRescisaoTSVDesligados } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetVinculosRescisaoTSVDesligados'
import { useRescisaoTSVDesfazer } from '~/hooks/queries/Vinculo/useRescisaoTSVDesfazer'

export default function DesfazerRescisaoTSV() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetVinculosRescisaoTSVDesligados({
    params: {
      estabelecimentoId: estabelecimento.id,
      anoMes,
    },
  })
  const data = _d || []

  const { mutateAsync, isLoading: isSubmitting } = useRescisaoTSVDesfazer()

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento, anoMes])

  async function handleSubmit() {
    const ids = rowsSelecteds.map((index) => data[index].id)
    await mutateAsync({ data: ids })
    setRowsSelected([])
  }

  return (
    <Stack height="100%">
      <Stack component={Paper} p={1} alignItems="flex-end">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Stack>

      <ContainerTable>
        <Table
          query={query}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelecteds}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </ContainerTable>

      <Stack component={Paper} alignItems="flex-end" p={1}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={!(rowsSelecteds.length > 0)}
        >
          Desfazer Rescisão TSV
        </Button>
      </Stack>
    </Stack>
  )
}
