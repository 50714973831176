import React from 'react'

import { Box, useTheme, IconButton } from '@material-ui/core'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import { Button, Checkbox, Finder } from '~/components'

import Form from '../Form'
import Table from '../Table'
import DialogAlerts from '../DialogAlerts'
import DialogConcedidaProgramada from '../DialogConcedidaProgramada'
import DialogLicencaRemunerada from '../DialogLicencaRemunerada'

import { useFeriasColetiva } from '../Context'
import { ButtonBox } from 'mio-library-ui'

export default function FeriasColetivaContent() {
  const { formProps, tableProps, requestProps } = useFeriasColetiva()

  const {
    paramsPage,
    onChangeAbono,
    onChangeFilterSetor,
    isOpenCollapseForm,
    setOpenCollapseForm,
  } = formProps
  const { collection, rowsSelected, setQuery } = tableProps
  const { onProcess, isSubmitting } = requestProps

  const theme = useTheme()

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={1}
        paddingRight={1}
        paddingLeft={1}
      >
        <Box display="flex" gridGap={theme.spacing(1)} width={350}>
          <Checkbox
            value={paramsPage.isAbono}
            label="Férias com Abono"
            onChange={(e, checked) => onChangeAbono(checked)}
          />
          <Checkbox
            value={paramsPage.isFilterBySetor}
            label="Filtrar por Setor"
            onChange={(e, checked) => onChangeFilterSetor(checked)}
          />
        </Box>

        <Box display="flex" gridGap={theme.spacing(1)}>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />

          <IconButton size="small" onClick={() => setOpenCollapseForm(!isOpenCollapseForm)}>
            {isOpenCollapseForm ? (
              <CollapseCloseIcon color="primary" />
            ) : (
              <CollapseOpenIcon color="primary" />
            )}
          </IconButton>
        </Box>
      </Box>
      <Form />

      <Box
        height={isOpenCollapseForm ? 'calc(100vh - 24rem)' : 'calc(100vh - 15rem)'}
        minHeight={500}
      >
        <Table />
      </Box>

      <DialogAlerts />
      <DialogConcedidaProgramada />
      <DialogLicencaRemunerada />

      {collection.length > 0 && (
        <ButtonBox top={1} bottom={1}>
          <Button
            isLoading={isSubmitting}
            disabled={!(rowsSelected.length > 0)}
            onClick={onProcess}
            variant="contained"
          >
            Processar
          </Button>
        </ButtonBox>
      )}
    </Box>
  )
}
