export enum IndDiasFeriasProporcionaisRestantesEnum {
  Usar_Dias_Ferias_Atual_1 = 1,
  Usar_Dias_Periodo_Atual_2 = 2,
  Usar_Dias_Periodo_Seguinte_3 = 3,
}

export const IndDiasFeriasProporcionaisRestantesValues = [
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Ferias_Atual_1,
    name: 'Fazer Férias Coletiva com o Total de Dias de Direito',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Periodo_Atual_2,
    name: 'Manter o Período Atual em aberto com o Saldo dos Dias restantes',
  },
  {
    value: IndDiasFeriasProporcionaisRestantesEnum.Usar_Dias_Periodo_Seguinte_3,
    name: 'Manter o Saldo dos Dias restantes no Período Seguinte',
  },
]
