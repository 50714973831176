import { useMemo } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Badge, IconButton, useTheme } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { CompetenciaEmpregadorDTO } from '~/hooks/queries/Competencia/CompetenciaEmpregadorDTO'

import { CompetenciaLogDTO } from '~/hooks/queries/Competencia/CompetenciaLogDTO'
import useDialog from '~/hooks/useDialog'
import DialogProblems from './DialogProblems'
import ComponentStatus from './ComponentStatus'

interface TableProps {
  data: CompetenciaEmpregadorDTO[]
  isLoading: boolean
  isFetching: boolean
  rowsSelected: number[]
  onRowSelected: (rows: number[]) => void
  query: string
}

export default function Table({
  data,
  isLoading,
  isFetching,
  rowsSelected,
  onRowSelected,
  query,
}: TableProps) {
  const theme = useTheme()
  const {
    close: closeDialogProblems,
    data: dataDialogProblems,
    isOpen: isOpenDialogProblems,
    open: openDialogProblems,
  } = useDialog<CompetenciaLogDTO[]>([])

  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        name: 'empregadorCodigo',
        label: 'Código',
      },
      {
        name: 'empregadorNome',
        label: 'Nome',
      },
      {
        name: 'empregadorNrInscricao',
        label: 'Número de Inscrição',
        options: {
          customBodyRender: (value) => (
            <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
          ),
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customBodyRender: (value) => (
            <ButtonBox spacing={0} justifyContent="center">
              <ComponentStatus status={value} />
            </ButtonBox>
          ),
        },
      },
      {
        name: 'logs',
        label: 'Problemas',
        options: {
          customBodyRender: (value: CompetenciaLogDTO[] | undefined) => {
            const arrayLogs = value || []
            const disabled = arrayLogs.length > 0 ? false : true
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <Badge color="secondary" badgeContent={arrayLogs.length}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => openDialogProblems(arrayLogs)}
                    disabled={disabled}
                  >
                    <Warning style={{ color: disabled ? '' : theme.palette.warning.main }} />
                  </IconButton>
                </Badge>
              </ButtonBox>
            )
          },
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ToolsDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        optionsSelectable={{
          onRowSelected,
          rowsSelected,
          type: 'multiple',
        }}
        sherlock={{
          query,
          columns: ['empregadorCodigo', 'empregadorNome', 'empregadorNrInscricao'],
        }}
      />
      <DialogProblems
        data={dataDialogProblems}
        isOpen={isOpenDialogProblems}
        onClose={closeDialogProblems}
      />
    </>
  )
}
