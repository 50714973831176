import React, { useMemo } from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { HeightDataTable } from '~/components'

const Table = (props) => {
  const { data, onItemClick, isLoading } = props

  const columns = useMemo(
    () => [
      {
        name: 'tipoIntervalo',
        label: 'Tipo Intervalo',
        options: {
          customBodyRender: (value) => {
            const map = {
              1: '1 - Fixo',
              2: '2 - Variável',
            }
            return map[value] || value
          },
        },
      },
      {
        name: 'inicioIntervalo',
        label: 'Início Intervalo',
        options: {
          customBodyRender: (value) => (value ? `${value.substr(0, 2)}:${value.substr(2, 2)}` : 0),
        },
      },
      {
        name: 'fimIntervalo',
        label: 'Fim Intervalo',
        options: {
          customBodyRender: (value) => (value ? `${value.substr(0, 2)}:${value.substr(2, 2)}` : 0),
        },
      },
      {
        name: 'duracaoIntervalo',
        label: 'Duração Intervalo min(s)',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => {
                    onItemClick('edit', value)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  title="Deletar este registro"
                  color="primary"
                  aria-label="Deletar"
                  onClick={() => {
                    onItemClick('delete', value)
                  }}
                >
                  <DeleteIcon fontSize="small" color="primary" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ],
    [onItemClick],
  )

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
