import React, { useState, useEffect } from 'react'

import { TextField, MenuItem, CircularProgress } from '@material-ui/core'
import api from '~/services/api-pessoal'

const ReporterSelector = (props) => {
  const { reportKey, value, onChange } = props
  const [reports, setReports] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const getReports = async () => {
      setLoading(true)
      try {
        const response = await api.get('/Relatorio/GetByChaveId', {
          params: {
            chaveId: reportKey,
          },
        })
        if (Array.isArray(response.data.data)) {
          onChange(response.data.data[0])
        }
        setReports(response.data.data)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
    getReports()
  }, [reportKey, onChange])

  if (isLoading) return <CircularProgress size={20} />

  return (
    <TextField
      size="small"
      fullWidth
      select
      variant="outlined"
      value={value?.id || ''}
      onChange={(e) => {
        const report = reports.find((r) => r.id === e.target.value)
        onChange(report)
      }}
    >
      {reports.map((r) => (
        <MenuItem key={r.id} value={r.id}>
          {r.nome}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default ReporterSelector
