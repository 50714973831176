import { useCallback, useEffect, useState } from 'react'

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

export { HubConnection }

interface UseSocketProps {
  urlHub: string
  group: string
  onStartConnection: (socket: HubConnection) => void
}

export default function useSocket({ urlHub, group, onStartConnection }: UseSocketProps) {
  const [socket, setSocket] = useState<HubConnection | null>(null)

  const startConnection = useCallback(() => {
    if (socket) {
      socket
        .start()
        .then(async () => {
          await socket.send('AddToGroup', group)
          await onStartConnection(socket)
        })
        .catch((e) => {
          console.info(`[${socket.baseUrl}] Socket connection failed: `, e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, group])

  const stopConnection = useCallback(async () => {
    if (socket) {
      await socket.send('RemoveFromGroup', group)
      socket.stop().catch((e) => {
        console.info(`[${socket.baseUrl}] Socket disconnection failed: `, e)
      })
    }
  }, [socket, group])

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(import.meta.env.VITE_PESSOAL_API_URL + urlHub)
      .withAutomaticReconnect()
      .build()
    setSocket(connection)
  }, [urlHub])

  useEffect(() => {
    startConnection()
    return () => {
      stopConnection()
    }
  }, [startConnection, stopConnection])
}
