import { toast } from 'react-toastify'

import api from '~/services/api-pessoal'
import moment from 'moment'

import { ReciboIDFValues, ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

export const formatIdfReciboPagamento = (idf) => {
  const objectFinded = ReciboIDFValues.find((obj) => obj.value === idf)
  return objectFinded.name
}

export function formatPISPASSEP(pisPassep) {
  const pisPassepWithoutNumber = pisPassep.replace(/\D/g, '')
  const pisPassepFormated = pisPassepWithoutNumber.replace(
    /^(\d{3})(\d{5})(\d{2})(\d{1})$/,
    '$1.$2.$3-$4',
  )
  return pisPassepFormated
}

export function openDownloadData(fileName, data) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/octet-stream',
    }),
  )
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  setTimeout(function () {
    window.URL.revokeObjectURL(link)
  }, 200)
}

const useUtils = () => {
  const diffBetweenTimeInMinutes = (dateStart, dateEnd) => {
    if (dateEnd < dateStart) {
      dateEnd.setDate(dateEnd.getDate() + 1)
    }

    var diff = (dateEnd.getTime() - dateStart.getTime()) / 1000

    return diff / 60
  }

  const formatAnoMes = (value) => {
    if (!value) return ''
    return value.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1/$2')
  }

  function formatDiaMes(value) {
    if (!value) return
    return value.substr(0, 2).concat('/').concat(value.substr(-2))
  }

  const formatCurrency = (value) => {
    if (!value) return ''
    return Number(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    })
  }

  const formatMonth = (value) => {
    const monthMaps = {
      '01': 'Janeiro',
      '02': 'Fevereiro',
      '03': 'Março',
      '04': 'Abril',
      '05': 'Maio',
      '06': 'Junho',
      '07': 'Julho',
      '08': 'Agosto',
      '09': 'Setembro',
      10: 'Outubro',
      11: 'Novembro',
      12: 'Dezembro',
    }
    return monthMaps[value]
  }

  function dateFormatDayMonthYear(date) {
    if (!date) return

    const dateFormatted = moment(date).format('DD/MM/yyyy')

    return dateFormatted
  }

  const obterDiaUtilMes = (date, feriados = [], contadorDiaUtil = 0) => {
    const DOMINGO = 0
    const N_DIAS_UTEIS = 5

    const d = date.format('D')
    const diaSemana = parseInt(date.day())

    const ehFeriado = feriados.includes(parseInt(d))
    const ehDiaUtil = diaSemana !== DOMINGO && !ehFeriado

    if (parseInt(contadorDiaUtil) === parseInt(N_DIAS_UTEIS)) return d - 1

    date.add(1, 'd')

    if (ehDiaUtil) {
      return obterDiaUtilMes(date, feriados, contadorDiaUtil + 1)
    }
    return obterDiaUtilMes(date, feriados, contadorDiaUtil)
  }

  const parseStringToFloat = (v) => {
    if (!v) return ''

    let value = String(v)
    value = value.replace(/\./g, '')
    value = value.replace(/,/g, '.')
    value = parseFloat(value)
    return value
  }

  const allowOnlyNumber = (value) => {
    const newValue = value?.replace(new RegExp(`[^0-9]`, 'g'), '')

    return newValue
  }

  const getRandomNumber = (max, min, decimalPlaces) => {
    if (decimalPlaces) return Number((Math.random() * max + min).toFixed(decimalPlaces))
    return Math.floor(Math.random() * max) + min
  }

  const getRandomDate = (start, end) => {
    const newStart = new Date(start)
    const newEnd = new Date(end)
    return new Date(newStart.getTime() + Math.random() * (newEnd.getTime() - newStart.getTime()))
  }

  const incrementDayToDate = (date, days) => {
    const newDate = moment(date).add(days, 'days').toDate()

    return newDate
  }

  return {
    diffBetweenTimeInMinutes,
    formatIdfReciboPagamento,
    formatAnoMes,
    formatDiaMes,
    formatCurrency,
    formatMonth,
    dateFormatDayMonthYear,
    obterDiaUtilMes,
    parseStringToFloat,
    getRandomNumber,
    getRandomDate,
    allowOnlyNumber,
    incrementDayToDate,
    openDownloadData,
  }
}

export function formatIdfReciboToReportKey(reciboIdf) {
  const reportKeysValues = {
    [ReciboIDFEnum.Mensal_1]: 'ReciboPagamento',
    [ReciboIDFEnum.Ferias_2]: 'Ferias',
    [ReciboIDFEnum.Rescisao_5]: 'TRCT',
    [ReciboIDFEnum.RescisaoComplementar_6]: 'TRCT',
    [ReciboIDFEnum.RescisaoSimulada_11]: 'RescisaoSimulada',
    [ReciboIDFEnum.Resilicao_14]: 'TRCT',
    [ReciboIDFEnum.Autonomo_16]: 'RPA',
  }
  return reportKeysValues[reciboIdf] || 'ReciboPagamento'
}

export function obterErroValidationErrors(name, validationErrors) {
  if (!validationErrors) return
  const { inner } = validationErrors
  const erroEncontrado = inner.find((item) => {
    const { path } = item
    return name === path
  })
  if (!erroEncontrado) return
  return erroEncontrado.message
}

export function obterTodosErroValidationErrors(validationErrors) {
  if (!validationErrors) return []
  const { inner } = validationErrors
  return inner.map((item) => item.message)
}

export function getDateMinAndDateMaxOfYearMonth(yearMonth) {
  const year = parseInt(yearMonth.substr(0, 4))
  const month = parseInt(yearMonth.substr(4)) - 1
  const date = new Date(year, month)

  const dtMin = moment(date).format('yyyy-MM-DD')
  const dtMax = moment(date).endOf('month').format('yyyy-MM-DD')
  return {
    dtMin,
    dtMax,
  }
}

export function dateHasBetweenYearMonth(date, yearMonth) {
  const { dtMin, dtMax } = getDateMinAndDateMaxOfYearMonth(yearMonth)

  return moment(date).isSameOrAfter(dtMin) && moment(date).isSameOrBefore(dtMax)
}

export async function verifyIfExistsMatricula(empregadorId, matricula) {
  const response = await api.get('/Vinculo/GetVinculoMatricula', {
    params: {
      empregadorId,
      matricula,
    },
  })
  if (response?.data?.data) throw new Error(response.data.data)
}

export async function getEmpregadorPorId(id) {
  const response = await api.get(`/EmpregadorConsulta/GetById?id=${id}`)
  return response?.data?.data
}

export function removeAccentsString(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export async function haveTabelaMesInAnoMes(anoMes) {
  const response = await api.get('/TabelaMes/CheckTabelaMes', {
    params: {
      anoMes,
    },
  })
  return !(
    response?.data?.data &&
    Array.isArray(response?.data?.data) &&
    response?.data?.data.length > 0
  )
}

export const formatCurrency = (value) => {
  if (!value) return ''
  return Number(value).toLocaleString('pt-br', {
    minimumFractionDigits: 2,
  })
}

export const formatCurrencyAcceptZeros = (value) => {
  return Number(value).toLocaleString('pt-br', {
    minimumFractionDigits: 2,
  })
}

export function getDeepDynamicPropValue(obj, propString) {
  if (!propString) return obj

  var prop,
    props = propString.split('.')

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i]

    var candidate = obj[prop]
    if (candidate !== undefined) {
      obj = candidate
    } else {
      break
    }
  }
  return obj[props[i]]
}

export const formatAnoMes = (value) => {
  if (!value) return ''
  return value.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1/$2')
}

export const incrementDayToDate = (date, days) => {
  const newDate = moment(date).add(days, 'days').toDate()

  return newDate
}

export const calcDiasAvisoPrevio = (dtInicio, dtAdmissaoFuncionario, repeat = true) => {
  if (!moment(dtInicio).isValid()) return
  if (!moment(dtAdmissaoFuncionario).isValid()) return
  const diffYears = moment(dtInicio).diff(dtAdmissaoFuncionario, 'years')
  let numeroDiasDeAvisoPrevio = 30 + diffYears * 3
  if (numeroDiasDeAvisoPrevio < 30) numeroDiasDeAvisoPrevio = 30
  if (numeroDiasDeAvisoPrevio > 90) numeroDiasDeAvisoPrevio = 90

  const newDtInicio = moment(dtInicio).add(numeroDiasDeAvisoPrevio, 'day').format('yyyy-MM-DD')

  if (repeat) {
    return calcDiasAvisoPrevio(newDtInicio, dtAdmissaoFuncionario, false)
  }
  return numeroDiasDeAvisoPrevio
}

export function openBlankTab(url) {
  window.open('#' + url, '_blank')
}

export function copyToClipboard(
  content,
  messageSuccess = `${content} foi copiado com sucesso para a Área de Transferência`,
) {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content)
    toast.success(messageSuccess)
  } else {
    toast.error('Não foi possível copiar para a Área de Transferência')
  }
}

export function orderBy(list, fieldName, order = 'asc') {
  return list.sort((a, b) => {
    const nomeA = a[fieldName].toString().toUpperCase()
    const nomeB = b[fieldName].toString().toUpperCase()
    if (nomeA < nomeB) {
      return order === 'asc' ? -1 : 1
    }
    if (nomeA > nomeB) {
      return order === 'asc' ? 1 : -1
    }
    return 0
  })
}

export function percentNumber(value, percent) {
  return parseFloat(((value * percent) / 100).toFixed(2))
}

export function getDataAtualPorExtenso() {
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const dataAtual = new Date()

  const dia = dataAtual.getDate()
  const mes = meses[dataAtual.getMonth()]
  const ano = dataAtual.getFullYear()

  const dataPorExtenso = `${dia} de ${mes} de ${ano}`

  return dataPorExtenso
}

export function formatPrettyAnoMes(anoMes) {
  return anoMes.substring(0, 4) + '/' + anoMes.substring(4)
}

export function setImportantCSS(value) {
  return value + '!important'
}

export function formatAnoMesInDate(anoMes) {
  const ano = anoMes.substring(0, 4)
  const mes = anoMes.substring(4)
  return new Date(ano, mes - 1, 1)
}

export function formatToDigits(number, digits) {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: digits,
    useGrouping: false,
  })
}

export function insertString(value, position, valueAdd) {
  return value.substring(0, position) + valueAdd + value.substring(position)
}

export function agruparPorTipo(data, field) {
  return data.reduce((acc, item) => {
    const fieldValue = item[field]
    let group = acc.find((d) => d.field === fieldValue)
    if (!group) {
      group = { field: fieldValue, data: [] }
      acc.push(group)
    }
    group.data.push(item)
    return acc
  }, [])
}

export default useUtils
