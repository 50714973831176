import React, { useState } from 'react'

import { ContainerComponent } from '~/components'

import EmpregadorSituacao from './components/EmpregadorSituacao'
import Form from './components/Cadastro'
import EstabelecimentosEmpregador from './components/EstabelecimentosEmpregador'
import SociosEmpregador from './components/SociosEmpregador'

const ContainerForm = (props) => {
  const { isOpen, onClose, data, onAfterSubmitForm, anoMes } = props

  const [wasModified, setWasModified] = useState(false)

  return (
    <ContainerComponent
      title="Cadastro de Empregador"
      isOpen={isOpen}
      onClose={onClose}
      formWasModified={wasModified}
      tabs={[
        {
          component: (
            <Form
              data={data}
              onAfterSubmitForm={onAfterSubmitForm}
              onClose={onClose}
              setWasModified={setWasModified}
            />
          ),
          label: 'Cadastro',
        },
        {
          component: <EmpregadorSituacao empregadorId={data.id} anoMes={anoMes} />,
          disabled: !data?.id ? true : false,
          label: 'Situação Tributária',
        },
        {
          component: <EstabelecimentosEmpregador empregadorId={data.id} />,
          disabled: !data?.id ? true : false,
          label: 'Estabelecimentos',
        },
        {
          component: <SociosEmpregador empregadorId={data.id} />,
          disabled: !data?.id ? true : false,
          label: 'Sócios',
        },
      ]}
    />
  )
}

export default ContainerForm
