import React, { useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { MUITableTwoRows } from '~/components'

import TableBaseCalculoCategoria from './components/TableBaseCalculoCate'

import { ApuracaoEncargosBaseCalculoDataLotacao } from '../..'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

interface TableBaseCalculoLotacaoProps {
  collection: ApuracaoEncargosBaseCalculoDataLotacao[]
  indApuracao: IndApuracaoEnum
  estabelecimentoId: string
}

const useStyles = makeStyles((theme) => ({
  tableWithError: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.error.light,
    },
  },
}))

export default function TableBaseCalculoLotacao(props: TableBaseCalculoLotacaoProps) {
  const { collection, indApuracao, estabelecimentoId } = props

  const [rowsExpanded, setRowsExpanded] = useState<number[]>([])

  const classes = useStyles()

  return (
    <Box mx={2} my={1}>
      <MUITableTwoRows
        data={collection}
        pagination={false}
        options={{
          rowExpanded: {
            expandChildren: (index) => (
              <TableBaseCalculoCategoria
                collection={collection[index].listCategoria}
                lotacaoId={collection[index].id}
                indApuracao={indApuracao}
                estabelecimentoId={estabelecimentoId}
              />
            ),
            onRowExpanded: setRowsExpanded,
            rowsExpanded: rowsExpanded,
          },
        }}
        columns={[
          {
            label: 'Lotação',
            firstRow: 'nome',
            options: {
              minWidth: '20rem',
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].isErro ? classes.tableWithError : '',
            },
          },
          {
            label: 'Apuração',
            firstRow: 'nome',
            secondRow: 'nome',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].isErro ? classes.tableWithError : '',
              customBodyRenderFirstRow: () => 'Folha',
              customBodyRenderSecondRow: () => 'eSocial',
            },
          },
          {
            label: 'FPAS',
            firstRow: 'fpas',
            secondRow: 'fpaS_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].isErro ? classes.tableWithError : '',
            },
          },
          {
            label: 'Terceiros',
            firstRow: 'terceiros',
            secondRow: 'terceiros_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].isErro ? classes.tableWithError : '',
            },
          },
          {
            label: 'Terceiros Suspenso',
            firstRow: 'terceirosSuspenso',
            secondRow: 'terceirosSuspenso_ESocial',
            options: {
              customStyleCell: (indexCurrent) =>
                collection[indexCurrent].isErro ? classes.tableWithError : '',
            },
          },
        ]}
      />
    </Box>
  )
}
