import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { CompetenciaEmpregadorFechamentoDTO } from './CompetenciaEmpregadorFechamentoDTO'
import { FiltroCompetenciaFechamentoEnum } from '~/@types/enums/FiltroCompetenciaFechamentoEnum'

export const queryObterEmpregadoresParaFechamento = '/Competencia/ObterEmpregadoresParaFechamento'

interface RequestProps {
  anoMes: string
  filtroEmpresas: FiltroCompetenciaFechamentoEnum
  grupoId: string | null
}

export default function useObterEmpregadoresParaFechamento(params: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: CompetenciaEmpregadorFechamentoDTO[] }>(
      queryObterEmpregadoresParaFechamento,
      {
        params,
      },
    )
    return response.data.data
  }

  return useQuery([queryObterEmpregadoresParaFechamento, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
