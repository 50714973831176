import { useMemo } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Badge, IconButton, useTheme } from '@material-ui/core'
import { Warning, Edit as EditIcon } from '@material-ui/icons'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import ComponentStatus from '../../Competencia/Table/ComponentStatus'
import DialogProblems from '../../Competencia/Table/DialogProblems'
import FormUpdate from './FormUpdate'

import useDialog from '~/hooks/useDialog'

import { CompetenciaEmpregadorFechamentoDTO } from '~/hooks/queries/Competencia/CompetenciaEmpregadorFechamentoDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { CompetenciaLogDTO } from '~/hooks/queries/Competencia/CompetenciaLogDTO'

interface TableProps {
  data: CompetenciaEmpregadorFechamentoDTO[]
  isLoading: boolean
  isFetching: boolean
  query: string
  tableRef: FixLater
}

export default function Table({ data, isLoading, isFetching, query, tableRef }: TableProps) {
  const {
    close: closeDialogProblems,
    data: dataDialogProblems,
    isOpen: isOpenDialogProblems,
    open: openDialogProblems,
  } = useDialog<CompetenciaLogDTO[]>([])
  const {
    close: closeFormUpdate,
    data: dataFormUpdate,
    isOpen: isOpenFormUpdate,
    open: openFormUpdate,
  } = useDialog<CompetenciaEmpregadorFechamentoDTO | null>(null)
  const theme = useTheme()

  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    return [
      {
        name: 'empregadorCodigo',
        label: 'Código',
      },
      {
        name: 'empregadorNome',
        label: 'Nome',
      },
      {
        name: 'empregadorNrInscricao',
        label: 'Número de Inscrição',
        options: {
          customBodyRender: (value) => (
            <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
          ),
        },
      },
      {
        name: 'isEvtRemuneracao',
        label: '1200',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'isEvtPagamento',
        label: '1210',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'isEvtComercializacao',
        label: '1260',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'isEvtContratacaoAvulso',
        label: '1270',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'isEvtInfoComplementar',
        label: '1280',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customBodyRender: (value) => (
            <ButtonBox spacing={0} justifyContent="center">
              <ComponentStatus status={value} />
            </ButtonBox>
          ),
        },
      },
      {
        name: 'logs',
        label: 'Problemas',
        options: {
          customBodyRender: (value: CompetenciaLogDTO[] | undefined) => {
            const arrayLogs = value || []
            const disabled = arrayLogs.length > 0 ? false : true
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <Badge color="secondary" badgeContent={arrayLogs.length}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => openDialogProblems(arrayLogs)}
                    disabled={disabled}
                  >
                    <Warning style={{ color: disabled ? '' : theme.palette.warning.main }} />
                  </IconButton>
                </Badge>
              </ButtonBox>
            )
          },
        },
      },
      {
        name: 'reciboESocial',
        label: 'eSocial',
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value) => {
            const dtCurrent = data.find((d) => d.id === value)
            if (!dtCurrent) return
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  size="small"
                  title="Editar este registro"
                  color="primary"
                  aria-label="Editar"
                  onClick={() => openFormUpdate(dtCurrent)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <ToolsDataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        disableAutoHeight
        sherlock={{
          query,
          columns: ['empregadorCodigo', 'empregadorNome', 'empregadorNrInscricao'],
        }}
        optionsSelectable={{
          type: 'multiple',
        }}
        tableRef={tableRef}
      />
      <DialogProblems
        data={dataDialogProblems}
        isOpen={isOpenDialogProblems}
        onClose={closeDialogProblems}
      />
      {dataFormUpdate && (
        <FormUpdate data={dataFormUpdate} isOpen={isOpenFormUpdate} onClose={closeFormUpdate} />
      )}
    </>
  )
}
