import { useEffect, useState } from 'react'

import { Paper } from '@material-ui/core'

import { Finder, Button, Stack, ContainerTable } from '~/components'

import Table from './Table'
import Form from './Form'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useVinculoConsultaGetVinculosRescisaoTSVAtivos } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetVinculosRescisaoTSVAtivos'

export default function GerarRescisaoTSV() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])

  const {
    close: closeForm,
    isOpen: isOpenForm,
    data: dataForm,
    open: openForm,
  } = useDialog<string[]>([])

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetVinculosRescisaoTSVAtivos({
    params: {
      estabelecimentoId: estabelecimento.id,
      anoMes,
    },
  })
  const data = _d || []

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento, anoMes])

  function handleOpenForm() {
    const ids = rowsSelecteds.map((index) => data[index].id)
    openForm(ids)
  }

  function handleAfterSubmit() {
    closeForm()
    setRowsSelected([])
  }

  return (
    <Stack height="100%">
      <Stack component={Paper} p={1} alignItems="flex-end">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </Stack>

      <ContainerTable>
        <Table
          query={query}
          onRowSelected={setRowsSelected}
          rowsSelected={rowsSelecteds}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </ContainerTable>

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          vinculosSelecionados={dataForm}
          onAfterSubmit={handleAfterSubmit}
        />
      )}

      <Stack component={Paper} alignItems="flex-end" p={1}>
        <Button variant="contained" onClick={handleOpenForm} disabled={!(rowsSelecteds.length > 0)}>
          Gerar Rescisão TSV
        </Button>
      </Stack>
    </Stack>
  )
}
