import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import { Grid, makeStyles, Box, Divider, LinearProgress } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { Checkbox, ActionDialog, Button, ContentDivider, TextField, TimePicker } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import CadastroIntervalo from './components/CadastroIntervalo'

import useDialog from '~/hooks/useDialog'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  HorarioTrabalho,
  HorarioTrabalhoForm,
} from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'
import {
  HorarioTrabalhoIntervalo,
  HorarioTrabalhoIntervaloForm,
} from '~/hooks/queries/HorarioTrabalhoIntervalo/HorarioTrabalhoIntervalo'

export const calcularDuracaoJornada = (
  horaSaida: string,
  horaEntrada: string,
  intervalos: HorarioTrabalhoIntervalo[],
) => {
  const format = 'HHmm'

  const dateEntrada = moment(horaEntrada, format)
  let dateSaida = moment(horaSaida, format)

  if (dateSaida.isBefore(dateEntrada)) {
    dateSaida = moment(dateSaida).add(1, 'days')
  }

  let duracao = dateSaida.diff(dateEntrada, 'minutes')

  intervalos.forEach((intervalo) => (duracao -= intervalo.duracaoIntervalo))

  return duracao
}

const calcularDuracaoJornadaNoturna = (horaSaida: string, horaEntrada: string) => {
  const format = 'HHmm'

  const dateEntrada = moment(horaEntrada, format)
  let dateSaida = moment(horaSaida, format)

  const startHoraNoturna = moment('2200', format)
  const endHoraNoturna = moment('0500', format)

  if (dateSaida.isBefore(dateEntrada)) {
    dateSaida = moment(dateSaida).add(1, 'days')
  }

  let minutosNortuno = 0

  while (dateEntrada.isBefore(dateSaida)) {
    if (dateEntrada.isSameOrAfter(startHoraNoturna) || dateEntrada.isBefore(endHoraNoturna)) {
      minutosNortuno++
    }

    dateEntrada.add(1, 'minutes')
  }

  return minutosNortuno
}

const useStyles = makeStyles(() => ({
  heightPaper: {
    height: '70%',
  },
}))

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  horaEntrada: yup
    .string()
    .required('Informe a Entrada')
    .min(4, 'Informe um horário válido')
    .nullable(),
  horaSaida: yup
    .string()
    .required('Informe a Saída')
    .min(4, 'Informe um horário válido')
    .nullable(),
})

interface CadastroHorarioTrabalhoProps {
  isOpen: boolean
  onClose: () => void
  data: HorarioTrabalhoForm
  onAfterSubmitForm: (
    event: 'insert' | 'update',
    data: HorarioTrabalho,
    isCloseForm?: boolean,
  ) => void
}

export default function CadastroHorarioTrabalho(props: CadastroHorarioTrabalhoProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props

  const [data, setData] = useState(_data)

  const [isLoadingDeleteIntervalo, setLoadingDeleteIntervalo] = useState(false)
  const [isLoadingStartForm, setLoadingStartForm] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()

  const {
    close: closeFormIntervalo,
    data: dataFormIntervalo,
    isOpen: isOpenFormIntervalo,
    open: openFormIntervalo,
  } = useDialog<HorarioTrabalhoIntervaloForm | null>(null)
  const {
    close: closeDeleteIntervalo,
    data: dataDeleteIntervalo,
    isOpen: isOpenDeleteIntervalo,
    open: openDeleteIntervalo,
  } = useDialog<string | null>(null)

  useEffect(() => {
    const getCollection = async () => {
      if (!_data?.id) return
      setLoadingStartForm(true)
      try {
        const response = await api.get('/HorarioTrabalhoIntervalo/GetByHorarioTrabalho', {
          params: {
            horarioTrabalhoId: _data.id,
          },
        })
        setData((prev) => ({
          ...prev,
          horarioTrabalhoIntervalo: response?.data?.data || [],
        }))
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingStartForm(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [_data])

  const handleSubmit = () => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/horarioTrabalho/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/horarioTrabalho', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleClickItem = (event: 'edit' | 'delete', id: string) => {
    const handleClickEditItem = () => {
      if (!data.horarioTrabalhoIntervalo) return
      const item = data.horarioTrabalhoIntervalo.find((i) => i.id === id)
      item && handleOpenForm(item)
    }

    const handleClickDeleteItem = () => {
      openDeleteIntervalo(id)
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteIntervalo = async () => {
    if (!data.horarioTrabalhoIntervalo) return
    setLoadingDeleteIntervalo(true)
    try {
      await api.delete(`/horarioTrabalhoIntervalo/${dataDeleteIntervalo}`)
      const newItens = data.horarioTrabalhoIntervalo.filter((i) => i.id !== dataDeleteIntervalo)
      await atualizarDuracaoJornada(newItens)
      closeDeleteIntervalo()
      setData((oldState) => ({
        ...oldState,
        horarioTrabalhoIntervalo: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingDeleteIntervalo(false)
    }
  }

  const handleClickAddItem = () => {
    handleOpenForm({
      horarioTrabalhoId: data.id,
      tipoIntervalo: '1',
      duracaoIntervalo: null,
      inicioIntervalo: null,
      fimIntervalo: null,
      fbCodigo: null,
    })
  }

  const handleOpenForm = (data: HorarioTrabalhoIntervaloForm) => {
    openFormIntervalo(data)
  }

  const handleAfterSubmitFormIntervalo = (
    event: 'insert' | 'update',
    value: HorarioTrabalhoIntervalo,
  ) => {
    const handleAfterInsert = async () => {
      const itens = data.horarioTrabalhoIntervalo || []
      const newItens = [value, ...itens]
      await atualizarDuracaoJornada(newItens)
      setData((prev) => ({
        ...prev,
        horarioTrabalhoIntervalo: newItens,
      }))
      closeFormIntervalo()
    }

    const handleAfterUpdate = async () => {
      const itens = data.horarioTrabalhoIntervalo || []
      const newItens = itens.map((i) => (i.id === value.id ? value : i))
      await atualizarDuracaoJornada(newItens)
      setData((prev) => ({
        ...prev,
        horarioTrabalhoIntervalo: newItens,
      }))
      closeFormIntervalo()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    functions[event]()
  }

  const atualizarDuracaoJornada = async (intervalos: HorarioTrabalhoIntervalo[]) => {
    if (!data.horaSaida || !data.horaEntrada) return
    setSubmitting(true)
    try {
      const duracaoJornada = calcularDuracaoJornada(data.horaSaida, data.horaEntrada, intervalos)
      const response = await api.put(`/horarioTrabalho/${data.id}`, { ...data, duracaoJornada })
      const responseCurrent = response.data.data
      onAfterSubmitForm('update', responseCurrent, false)
      setData(responseCurrent)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: data?.id ? 'lg' : 'sm',
        fullWidth: true,
        classes: {
          paper: data?.id ? classes.heightPaper : undefined,
        },
      }}
    >
      {isLoadingStartForm ? <LinearProgress /> : <Box height={4} />}
      <Grid container spacing={2}>
        <Grid item xl={data?.id ? 1 : 2} lg={data?.id ? 1 : 2} md={2} sm={2} xs={12}>
          <TextField label="Código" fullWidth value={data.codigo || ''} size="small" disabled />
        </Grid>

        <Grid item xl={data?.id ? 5 : 10} lg={data?.id ? 5 : 10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            size="small"
            required
            autoFocus
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value || ''
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={6} sm={6} xs={12}>
          <TimePicker
            label="Entrada"
            value={data?.horaEntrada ? moment(data.horaEntrada, 'HHmm').format('HH:mm') : null}
            required
            validationErrors={validationErrors}
            name="horaEntrada"
            onChange={(_, date) => {
              const horaEntrada = date?.format('HHmm') || null
              const horaSaida = data.horaSaida
              let duracaoJornada: number | null = null
              let duracaoJornadaNoturna: number | null = null
              const intervalos = data.horarioTrabalhoIntervalo || []

              if (horaSaida && horaEntrada) {
                duracaoJornada = calcularDuracaoJornada(horaSaida, horaEntrada, intervalos)
                duracaoJornadaNoturna = calcularDuracaoJornadaNoturna(horaSaida, horaEntrada)
              }

              setData((prev) => ({
                ...prev,
                horaEntrada,
                horaSaida,
                duracaoJornada,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={6} sm={6} xs={12}>
          <TimePicker
            label="Saída"
            value={data?.horaSaida ? moment(data.horaSaida, 'HHmm').format('HH:mm') : null}
            required
            validationErrors={validationErrors}
            name="horaSaida"
            onChange={(_, date) => {
              const horaEntrada = data.horaEntrada
              const horaSaida = date?.format('HHmm') || null
              let duracaoJornada: number | null = null
              let duracaoJornadaNoturna: number | null = null
              const intervalos = data.horarioTrabalhoIntervalo || []

              if (horaSaida && horaEntrada) {
                duracaoJornada = calcularDuracaoJornada(horaSaida, horaEntrada, intervalos)
                duracaoJornadaNoturna = calcularDuracaoJornadaNoturna(horaSaida, horaEntrada)
              }

              setData((prev) => ({
                ...prev,
                horaEntrada,
                horaSaida,
                duracaoJornada,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={data?.id ? 3 : 6} sm={6} xs={12}>
          <TextField
            label="Duração (min)"
            fullWidth
            value={data?.duracaoJornada || ''}
            size="small"
            required
            validationErrors={validationErrors}
            name="duracaoJornada"
            disabled
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={data?.id ? 3 : 6} sm={6} xs={12}>
          <TextField
            label="Duração Jornada Noturna(min)"
            fullWidth
            value={data?.duracaoJornadaNoturna || ''}
            size="small"
            onChange={(e) => {
              const duracaoJornadaNoturna = parseInt(e.target.value || '0')
              setData((prev) => ({
                ...prev,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={data?.id ? 3 : 6} sm={6} xs={12}>
          <TextField
            label="Horas compensadas de sabádo(min)"
            size="small"
            onlyNumber
            fullWidth
            inputProps={{ maxLength: 10 }}
            value={data?.duracaoJornadaSabado || ''}
            onChange={(e) => {
              const duracaoJornadaSabado = parseInt(e.target.value || '0')
              setData((oldState) => ({
                ...oldState,
                duracaoJornadaSabado,
              }))
            }}
          />
        </Grid>

        <Grid item xl={data?.id ? 3 : 6} lg={data?.id ? 3 : 6} md={data?.id ? 3 : 6} sm={6} xs={12}>
          <Checkbox
            label="Horário Flexível"
            value={data?.permiteHorarioFlexivel || false}
            onChange={(e, value) => {
              const permiteHorarioFlexivel = value
              setData({
                ...data,
                permiteHorarioFlexivel,
              })
            }}
          />
        </Grid>

        {data?.id && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
        )}
      </Grid>

      {data?.id && (
        <>
          <Box py={1}>
            <ContentDivider
              title="Intervalos de Horário de Trabalho"
              renderRight={
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleClickAddItem}
                    variant="contained"
                  >
                    Adicionar
                  </Button>
                </ButtonBox>
              }
            />
          </Box>

          <Table
            data={data.horarioTrabalhoIntervalo || []}
            isLoading={isLoadingDeleteIntervalo}
            onItemClick={handleClickItem}
          />
        </>
      )}

      {isOpenFormIntervalo && dataFormIntervalo ? (
        <CadastroIntervalo
          isOpen={isOpenFormIntervalo}
          data={dataFormIntervalo}
          onClose={closeFormIntervalo}
          onAfterSubmitFormIntervalo={handleAfterSubmitFormIntervalo}
        />
      ) : (
        <></>
      )}

      <ConfirmDeleteDialog
        isOpen={isOpenDeleteIntervalo}
        isDeleting={isLoadingDeleteIntervalo}
        onCancel={closeDeleteIntervalo}
        onConfirm={handleDeleteIntervalo}
      />
    </ActionDialog>
  )
}
