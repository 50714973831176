import { Box, Grid } from '@material-ui/core'

import { PageHeader, IconCard } from '~/components'
import { IconCardProps } from '../../components/IconCard'

export default function AuxiliaresFuncionario() {
  const data: IconCardProps[] = [
    {
      title: 'Transferência',
      icon: 'transfer_within_a_station',
      link: '/transferencia-funcionario',
    },
    {
      title: 'Cancelar Transferência',
      icon: 'block',
      link: '/cancelar-transferencia-funcionario',
    },
    {
      title: 'Intermitente',
      icon: 'assignment',
      link: '/trabalho-intermitente',
    },
    {
      title: 'Reintegração',
      icon: 'assignment_return',
      link: '/reintegracao',
    },
    {
      title: 'Adiantamento',
      icon: 'monetization_on',
      link: '/configurar-adiantamento-salario',
    },
    {
      title: 'Alterar Categoria',
      icon: 'category',
      link: '/alterar-categoria',
    },
    {
      title: 'Alterar CPF',
      icon: 'people',
      link: '/alterar-inscricao',
    },
    {
      title: 'Retificar Admissão',
      icon: 'account_circle',
      link: '/funcionario-retificar',
    },
  ]

  return (
    <Box height="100%" width="100%" padding={2}>
      <PageHeader title="Auxiliares Funcionário" />
      <Grid container spacing={2}>
        {data.map(({ title, icon, link }) => (
          <Grid key={link} item xs={12} sm={6} md={4} lg={3}>
            <IconCard title={title} icon={icon} link={link} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
