import { ActionDialog, Button, ButtonBox, HeightDataTable } from '~/components'

import useGetByHorarioTrabalho from '~/hooks/queries/HorarioTrabalhoIntervalo/useGetByHorarioTrabalho'

interface DialogIntervalosProps {
  isOpen: boolean
  onClose: () => void
  horarioId: string
}

export default function DialogIntervalos(props: DialogIntervalosProps) {
  const { isOpen, onClose, horarioId } = props

  const { data: _data, isLoading, isFetching } = useGetByHorarioTrabalho(horarioId)
  const data = _data
    ? _data.map((d) => ({
        ...d,
        tipoIntervalo: d?.tipoIntervalo === '1' ? '1 - Fixo' : '2 - Variável',
        inicioIntervalo: d.inicioIntervalo
          ? `${d.inicioIntervalo.substring(0, 2)}:${d.inicioIntervalo.substring(2)}`
          : '',
        fimIntervalo: d.fimIntervalo
          ? `${d.fimIntervalo.substring(0, 2)}:${d.fimIntervalo.substring(2)}`
          : '',
      }))
    : []

  const columns = [
    {
      name: 'tipoIntervalo',
      label: 'Tipo Intervalo',
    },
    {
      name: 'inicioIntervalo',
      label: 'Início Intervalo',
    },
    {
      name: 'fimIntervalo',
      label: 'Fim Intervalo',
    },
    {
      name: 'duracaoIntervalo',
      label: 'Duração Intervalo min(s)',
    },
  ]

  return (
    <ActionDialog
      title="Intervalos do Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <HeightDataTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </ActionDialog>
  )
}
