import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import { useInvalidate } from '../VinculoConsulta/useVinculoConsultaGetVinculosRescisaoTSVDesligados'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
}

export function useRescisaoTSVDesfazer() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/Vinculo/RescisaoTSVDesfazer', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('As rescisões TSV foram desfeitas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
