import React, { useEffect, useState } from 'react'

import * as yup from 'yup'
import moment from 'moment'

import { ContentDivider } from 'mio-library-ui'
import {
  Box,
  Grid,
  Paper,
  Container,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core'

import { DatePicker, PageHeader, PrintButton, PDFViewer, SimpleSelect } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getVinculoById } from '~/hooks/queries/useVinculo'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe o Funcionário'),
  dtRescisao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Rescisão')
    .nullable(),
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Assinatura')
    .nullable(),
})

const initialState = {
  vinculo: null,
  vinculoId: '',
  iniciativa: 'Empregador',
  dtRescisao: null,
  dtAssinatura: getDateCurrent(),
}

export default function RescisaoContratoExperiencia() {
  const [data, setData] = useState(initialState)
  const [isLoading, setLoading] = useState(false)

  const { anoMes, estabelecimento } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()

  useEffect(() => {
    setData(initialState)
  }, [anoMes, estabelecimento])

  function handleSubmit() {
    openPDFViewer(data)
  }

  async function handleChangeVinculo(e, _vinculo) {
    setLoading(true)
    let vinculo = null
    if (_vinculo?.id) vinculo = await getVinculoById(_vinculo.id)
    const vinculoId = _vinculo?.id || ''
    const dtRescisao = _vinculo?.dtRescisao || null
    setData((oldState) => ({
      ...oldState,
      vinculo,
      vinculoId,
      dtRescisao,
    }))
    setLoading(false)
  }

  const defaultValueShow = '-'

  const emissaoExperiencia = data?.vinculo?.dtAdmissao
    ? moment(data.vinculo.dtAdmissao).format('DD/MM/yyyy')
    : defaultValueShow

  const vencimentoCurrent =
    data?.vinculo?.contratoExperienciaDtProrrogacao ||
    data?.vinculo?.contratoExperienciaDtVencimento
  const vencimentoExperiencia = vencimentoCurrent
    ? moment(vencimentoCurrent).format('DD/MM/yyyy')
    : defaultValueShow

  const nrDiasExperienciaCurrent =
    data?.vinculo?.contratoExperienciaDiasProrrogacao || data?.vinculo?.contratoExperienciaDias
  const nrDiasExperiencia = nrDiasExperienciaCurrent || defaultValueShow

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Rescisão Antecipada Contrato Experiência" />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutoCompleteVinculo
                  label="Funcionário"
                  value={data?.vinculoId || ''}
                  estabelecimentoId={estabelecimento?.id}
                  anoMes={anoMes}
                  required
                  name="vinculoId"
                  optionId="id"
                  validationErrors={validationErrors}
                  onChange={handleChangeVinculo}
                />
              </Grid>

              {isLoading ? (
                <CircularProgress size={50} />
              ) : (
                <>
                  <Grid item xs={12}>
                    <SimpleSelect
                      label="Iniciativa"
                      value={data?.iniciativa}
                      onChange={(e, value) => {
                        const iniciativa = value
                        setData((prevState) => ({ ...prevState, iniciativa }))
                      }}
                      options={[
                        {
                          label: 'Empregador',
                          value: 'Empregador',
                        },
                        {
                          label: 'Empregado',
                          value: 'Empregado',
                        },
                      ]}
                      renderOption={(opt) => opt.label}
                      optionId="value"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      label="Data de Rescisão"
                      size="small"
                      value={data?.dtRescisao || null}
                      required
                      validationErrors={validationErrors}
                      name="dtRescisao"
                      onChange={(date) => {
                        const dtRescisao = date ? date.format('yyyy-MM-DD') : null
                        setData((prevState) => ({ ...prevState, dtRescisao }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label="Data de Assinatura"
                      size="small"
                      value={data?.dtAssinatura || null}
                      required
                      validationErrors={validationErrors}
                      name="dtAssinatura"
                      onChange={(date) => {
                        const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
                        setData((prevState) => ({ ...prevState, dtAssinatura }))
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ContentDivider title="Informações do Contrato" />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="body1">Emissão: </Typography>
                    <Typography variant="body1">{emissaoExperiencia}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="body1">Vencimento: </Typography>
                    <Typography variant="body1">{vencimentoExperiencia}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="body1">Número de Dias: </Typography>
                    <Typography variant="body1">{nrDiasExperiencia}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    xs={12}
                  >
                    <PrintButton onClick={handleValidate} />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Paper>
      </Container>
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey={'RescisaoContratoExperiencia' + data.iniciativa}
        title="Rescisão Antecipada Contrato Experiência"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/RescisaoContratoExperiencia/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
