import React from 'react'

import moment from 'moment'
import { formatToCNPJ, formatToCPF } from 'brazilian-values'

import { Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { ActionDialog } from '~/components'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import useAmbiente from '~/hooks/useAmbiente'

interface ConfirmInfoCadastroProps {
  isOpen: boolean
  onCancel: () => void
  onSubmit: (dt: VinculoPartial) => void
  data: VinculoPartial
}

export default function ConfirmInfoCadastro(props: ConfirmInfoCadastroProps) {
  const { isOpen, data, onCancel, onSubmit } = props

  const { estabelecimento } = useAmbiente()

  function handelSubmit() {
    onSubmit(data)
  }

  return (
    <ActionDialog
      title="Cadastro Inicial - Funcionário"
      isOpen={isOpen}
      onClose={onCancel}
      onCancelClick={onCancel}
      onOkClick={handelSubmit}
      okLabel="Confirmar"
      cancelLabel="Cancelar"
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
    >
      <Alert severity="info">
        <AlertTitle>
          <strong>Confirme as Informações do Cadastro</strong>
        </AlertTitle>
        <Typography>
          Funcionário:{' '}
          <strong>
            {data?.pessoaFisica?.nome}-{formatToCPF(data?.pessoaFisica?.nrInscricao || '')}
          </strong>
        </Typography>
        <Typography>
          Data de Admissão: <strong>{moment(data?.dtAdmissao).format('DD/MM/yyyy')}</strong>
        </Typography>
        <Typography>
          Estabelecimento:{' '}
          <strong>
            {estabelecimento.nome}-{formatToCNPJ(estabelecimento.nrInscricao)}
          </strong>
        </Typography>
      </Alert>
    </ActionDialog>
  )
}
