import { useState } from 'react'

import { Box, Tab, Tabs } from '@material-ui/core'

import { PageHeader, Stack, StackContainer, TabPanel } from '~/components'

import GerarRescisaoTSV from './GerarRescisaoTSV'
import DesfazerRescisaoTSV from './DesfazerRescisaoTSV'

enum TabRescisaoTSV {
  GerarRescisao,
  DesfazerRescisao,
}

const styleTab = {
  height: '100%',
}

export default function RescisaoTSV() {
  const [tab, setTab] = useState(TabRescisaoTSV.GerarRescisao)

  return (
    <StackContainer>
      <PageHeader title="Rescisão TSV">
        <Box width={450}>
          <Tabs value={tab} onChange={(e, value) => setTab(value)}>
            <Tab label="Gerar Rescisão TSV" />
            <Tab label="Desfazer Rescisão TSV" />
          </Tabs>
        </Box>
      </PageHeader>

      <Stack flex={1}>
        <TabPanel
          value={tab}
          index={TabRescisaoTSV.GerarRescisao}
          style={tab === TabRescisaoTSV.GerarRescisao ? styleTab : undefined}
        >
          <GerarRescisaoTSV />
        </TabPanel>
        <TabPanel
          value={tab}
          index={TabRescisaoTSV.DesfazerRescisao}
          style={tab === TabRescisaoTSV.DesfazerRescisao ? styleTab : undefined}
        >
          <DesfazerRescisaoTSV />
        </TabPanel>
      </Stack>
    </StackContainer>
  )
}
